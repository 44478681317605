import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'

const liff = window.liff;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {            
            userLineID: ""
        };
    }

    componentDidMount() {        
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "U0b81f50b7ae5779640000176e283010a";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/membercheck1stpage', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            //Check Member -> Show This page
                            this.setState({
                                userLineID: LineuserId
                            });
                        });
                    //action - end
                }
            });
        }
    }

  render () {
    return (
        <div className="container">
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <form id="myform">
                                    <fieldset className="fieldset-form pt-1">
                                        <div style={{ height: '100px' }} />
                                        <div className="row text-center mt-3 mb-0 justify-content-center">
                                            <div className="col-10 p-0">
                                                <div className="d-grid">
                                                    <Link to="/BookingTerm" className="btn btn-lg mt-2 mb-0 btn-register-2 lh-sm"><span className="fs-5">Book now<br />
                                                        <small className="fontkanit fs-6">จองห้องประชุม</small></span></Link>
                                                </div>
                                            </div>
                                            <div className="col-10 p-0">
                                                <div className="d-grid">
                                                    <Link to="/History" className="btn btn-lg mt-4 mb-0 btn-register-3 lh-sm"><span className="fs-5">Booking Status<br />
                                                        <small className="fontkanit fs-6">ตรวจสอบสถานะการจอง</small></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }                
            </div>
        </div>

    );
  }
}
