import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'
import svg01 from './media/icon-feather-calendar.svg'
import svg02 from './media/icon-meeting.svg'
import svg03 from './media/icon-feather-clock.svg'
import svg04 from './media/icon-user.svg'
import svg05 from './media/icon-feather-phone.svg'

const liff = window.liff;

export class BookingStep4V2 extends Component {
    static displayName = BookingStep4V2.name;

    constructor(props) {
        super(props);        

        this.state = {
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            jpage: "",
            userLineID: "",
            selectedstore: this.props.location.state.selectedstore,
            selecteddate: this.props.location.state.selecteddate,
            selectroomid: this.props.location.state.selectroomid,
            selectroom: this.props.location.state.selectroom,
            selectroomname: this.props.location.state.selectroomname,
            selectroommaxguest: this.props.location.state.selectroommaxguest,
            thisroomstoreaddr: this.props.location.state.thisroomstoreaddr,
            timestart: this.props.location.state.timestart,
            timeend: this.props.location.state.timeend,
            fullname: this.props.location.state.fullname,
            mobile: this.props.location.state.mobile,
            ans01: "",
            ans02: ""
        };

        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.closeModal = this.closeModal.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);
        this.closeModal3 = this.closeModal3.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none"
        });
    }

    closeModal2() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide"
        });
    }

    closeModal3() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide"
        });
    }

    async onSubmitForm() {
        //Good to go!! -> push to step4
        this.props.history.push({
            pathname: "/BookingStep5",
            state: {
                selectedstore: this.state.selectedstore,
                selecteddate: this.state.selecteddate,
                selectroomid: this.state.selectroomid,
                selectroom: this.state.selectroom,
                selectroomname: this.state.selectroomname,
                selectroommaxguest: this.state.selectroommaxguest,
                thisroomstoreaddr: this.state.thisroomstoreaddr,
                timestart: this.state.timestart,
                timeend: this.state.timeend,
                fullname: this.state.fullname,
                mobile: this.state.mobile
            }
        });
    }   

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "U0b81f50b7ae5779640000176e283010a";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end            

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            const dateoptions = {
                weekday: "short", day: "numeric", month: "short", year: "numeric"
            };

            var selecteddate = "";
            switch (this.state.selecteddate) {
                case "D0":
                    selecteddate = date0.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D1":
                    selecteddate = date1.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D2":
                    selecteddate = date2.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D3":
                    selecteddate = date3.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D4":
                    selecteddate = date4.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D5":
                    selecteddate = date5.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D6":
                    selecteddate = date6.toLocaleDateString('en-GB', dateoptions);
                    break;
                default:
                    break;
            }
            //Date list - end

            //
            var selectedstore = this.state.selectedstore;
            var selectroom = this.state.selectroomname;
            var selectroommaxguest = this.state.selectroommaxguest;
            var timestart = this.state.timestart;
            var timeend = this.state.timeend;
            var fullname = this.state.fullname;
            var mobile = this.state.mobile;
            var thisroomstoreaddr = this.state.thisroomstoreaddr;
        }

        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter p-3 pt-0 mt-0">
                                <h2 className="h5 text-center pt-4 mb-0 fontsodo-bold">ตรวจสอบรายละเอียดการจอง</h2>
                                <h3 className="h6 text-center mb-2 fontkanit fw-normal"> </h3>
                                <div className="detail-confirmed">
                                    <p className="text-center lh-sm mb-1"><span className="fs-6 fontsodo-semibold">Your booking is confirmed at</span><br />
                                        <span className="fs-5 fontsodo-bold text-green">{selectedstore}</span><br />
                                    </p>
                                    <div className="mb-2 text-center">
                                        <Link to={{ pathname: thisroomstoreaddr }} target="_blank" className="btn btn-sm mt-2 mb-0 btn-register-2 fontkanit w-auto"><img src={require("./media/icon-awesome-map-marked-alt.png")} alt="ดูเส้นทาง" className="ms-1 me-2" />ดูเส้นทาง</Link>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-8">
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold mb-0">
                                                <img src={svg01} alt="Date" className="ms-1 me-2" /> <small>{selecteddate}</small> <br />
                                                <img src={svg02} alt="Room" className="ms-1 me-2" /> <small>{selectroom} for {selectroommaxguest} people</small> <br />                                                
                                                <img src={svg03} alt="Time" className="ms-1 me-2" /> <small>{timestart} to {timeend}</small></p>
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold mb-0">
                                                <img src={svg04} alt="Time" className="ms-1 me-2" />  <span className="text-starbucks-green fontsodo-semibold"><small>{fullname}</small></span><br />
                                                <img src={svg05} alt="Time" className="ms-1 me-2" /> <span className="fontsodo-semibold"><small>{mobile}</small></span></p>
                                        </div>
                                        <div className="col-12">
                                            <p className="text-center lh-sm fs-6 fontsodo-semibold mb-1"><small><span className="text-green">We'll alert you 30 mins before your booking.</span><br />
                                                <span className="fontkanit">เราจะแจ้งเตือนล่วงหน้าก่อนถึงเวลาจอง 30 นาที</span></small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to={{
                                                pathname: "/BookingStep3",
                                                state: {
                                                    selectedstore: this.state.selectedstore,
                                                    selecteddate: this.state.selecteddate,
                                                    selectroomid: this.state.selectroomid,
                                                    selectroom: this.state.selectroom,
                                                    selectroomname: this.state.selectroomname,
                                                    selectroommaxguest: this.state.selectroommaxguest,
                                                    thisroomstoreaddr: this.state.thisroomstoreaddr,
                                                    timestart: this.state.timestart,
                                                    timeend: this.state.timeend,
                                                }
                                            }} className="btn btn-lg mb-0 btn-register-3">
                                                Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <span className="btn btn-lg mb-0 btn-register-2" onClick={this.onSubmitForm}>Next</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }                               
            </div>
        );
    }
}
