import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'
import svg01 from './media/icon-feather-calendar.svg'
import svg02 from './media/icon-meeting.svg'
import svg03 from './media/icon-feather-clock.svg'

const liff = window.liff;

export class BookingStep3 extends Component {
    static displayName = BookingStep3.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            jpage: "",
            userLineID: "",
            selectedstore: this.props.location.state.selectedstore,
            selecteddate: this.props.location.state.selecteddate,
            selectroomid: this.props.location.state.selectroomid,
            selectroom: this.props.location.state.selectroom,
            selectroomname: this.props.location.state.selectroomname,
            selectroommaxguest: this.props.location.state.selectroommaxguest,
            thisroomstoreaddr: this.props.location.state.thisroomstoreaddr,
            timestart: this.props.location.state.timestart,
            timeend: this.props.location.state.timeend,
            fullname: "",
            mobile: ""
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.fullname) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.mobile) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        } else if (this.state.mobile.length !== 10) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Good to go!! -> push to step4
            this.props.history.push({
                pathname: "/BookingStep4V2",
                state: {
                    selectedstore: this.state.selectedstore,
                    selecteddate: this.state.selecteddate,
                    selectroomid: this.state.selectroomid,
                    selectroom: this.state.selectroom,
                    selectroomname: this.state.selectroomname,
                    selectroommaxguest: this.state.selectroommaxguest,
                    thisroomstoreaddr: this.state.thisroomstoreaddr,
                    timestart: this.state.timestart,
                    timeend: this.state.timeend,
                    fullname: this.state.fullname,
                    mobile: this.state.mobile
                }
            });
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "U0b81f50b7ae5779640000176e283010a";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end            

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            const dateoptions = {
                weekday: "short", day: "numeric", month: "short", year: "numeric"
            };

            var selecteddate = "";
            switch (this.state.selecteddate) {
                case "D0":
                    selecteddate = date0.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D1":
                    selecteddate = date1.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D2":
                    selecteddate = date2.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D3":
                    selecteddate = date3.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D4":
                    selecteddate = date4.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D5":
                    selecteddate = date5.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D6":
                    selecteddate = date6.toLocaleDateString('en-GB', dateoptions);
                    break;
                default:
                    break;
            }
            //Date list - end

            //
            var selectedstore = this.state.selectedstore;
            var selectroomname = this.state.selectroomname;
            var selectroommaxguest = this.state.selectroommaxguest;
            var timestart = this.state.timestart;
            var timeend = this.state.timeend;
        }

        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <div className="form-enter p-3 pt-0 mt-4">
                                    <div className="row pb-0 mb-2">
                                        <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                                        <div className="col-10"><p className="text-green mb-0 fontkanit">{selectedstore}</p></div>
                                    </div>
                                    <div className="row pb-0 mb-2">
                                        <div className="col-2 text-end"><img src={svg01} alt="Date" className="ms-1 me-2" /></div>
                                        <div className="col-8"><p className="mb-0 fontsodo-semibold">{selecteddate}</p></div>
                                    </div>
                                    <div className="row pb-0 mb-2">
                                        <div className="col-2 text-end"><img src={svg02} alt="Room" className="ms-1 me-2" /></div>
                                        <div className="col-8"><p className="mb-0 fontsodo-semibold">{selectroomname} for {selectroommaxguest} people</p></div>
                                    </div>
                                    <div className="row pb-0 mb-2">
                                        <div className="col-2 text-end"><img src={svg03} alt="Time" className="ms-1 me-2" /></div>
                                        <div className="col-8"><p className="mb-0 fontsodo-semibold">{timestart} to {timeend}</p></div>
                                    </div>
                                    <h2 className="h5 text-center pt-3 mb-0 fontkanit">Name and Mobile Phone Number</h2>
                                    <h3 className="h6 text-center mb-2 fontkanit">กรอกชื่อและเบอร์โทรศัพท์ที่สามารถติดต่อได้</h3>
                                    <div className="row justify-content-center mb-4">
                                        <div className="col-8 text-center"><img src={require("./media/dot-page3.png")} alt="Page3" /></div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Name" required name="fullname" id="fullname" value={this.state.fullname} onChange={this.onInputchange} />
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control" maxLength="10" placeholder="Mobile Phone Number" required name="mobile" id="mobile" value={this.state.mobile} onChange={this.onInputchange} />
                                    </div>
                                </div>
                                <div className="box-footer-button position-relative">
                                    <div className="row text-center mt-3 mb-0 justify-content-center">
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <Link to={{
                                                    pathname: "/BookingStep2V2",
                                                    state: {
                                                        selectedstore: this.state.selectedstore,
                                                        selecteddate: this.state.selecteddate
                                                    }
                                                }} className="btn btn-lg mb-0 btn-register-3">
                                                    Back</Link>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <span className="btn btn-lg mb-0 btn-register-2" onClick={this.onSubmitForm}>Next</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {/* Custom Pop up */}
                                    <div id="popup1" className={this.state.ModelCss01}>
                                        <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                        <div className="text-center">
                                            <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                            <strong>และครบถ้วน</strong>
                                        </div>
                                        <ul className="text-start">
                                            <li className={this.state.ModelCssinner01}>กรุณาระบุชื่อผู้จอง</li>
                                            <li className={this.state.ModelCssinner02}>กรุณาระบุเบอร์โทรศัพท์</li>
                                        </ul>
                                        <div className="text-center mt-3 mb-2">
                                            <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>

        );
    }
}
