import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'

const liff = window.liff;

export class BookingTerm extends Component {
    static displayName = BookingTerm.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            jpage: "",
            userLineID: ""
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine')            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter p-3 pt-0 mt-4">
                                <div className="row p-3 pb-0">
                                    <div className="col-12">
                                        <h3 className="h5 text-start pt-0 mb-3 fontkanit">เงื่อนไขการใช้บริการ</h3>
                                        <p className="fontkanit small">- ทำการจองล่วงหน้าได้ไม่เกิน 7 วัน<br />
                                            - ใช้บริการได้สูงสุดครั้งละไม่เกิน 4 ชั่วโมง (หรือตามข้อกำหนดการใช้งานของร้าน)<br />
                                            - ใช้บริการได้ขั้นต่ำ 4 ท่าน และไม่เกินจำนวนความจุที่นั่งของห้องประชุม<br />
                                            - หากลูกค้าไม่แสดงตัวเข้าใช้งานภายใน 15 นาที หลังเวลาจอง ระบบจะทำการยกเลิกการจองของท่านโดยอัตโนมัติ<br />
                                            - ลูกค้าต้องสั่งซื้ออาหารและเครื่องดื่ม ยอดเฉลี่ยขั้นต่ำท่านละ 250 บาท (เริ่มต้นที่ 1,000 บาท สำหรับ 4 ท่าน)<br />
                                            - จำกัดการจอง Community Room 2 ครั้ง / วัน / 1 บัญชีผู้ใช้งาน (กรณียกเลิกการจอง ขอสงวนสิทธิ์ไม่คืนสิทธิ์การจอง)</p>
                                        <h3 className="h5 text-start pt-0 mb-3 fontkanit">Terms of Use</h3>
                                        <p className="fontsodo-semibold small">- A booking can be made up to 7 days in advance.<br />
                                            - Maximum 4 hours at a time (or according to the store’s conditions of use)<br />
                                            - Minimum 4 users per room and not to exceed the meeting room’s seating capacity.<br />
                                            - If the customer does not show up to the store within 15 minutes after the booking time, the system will automatically cancel the booking.<br />
                                            - Minimum average spending on food and beverage is 250 baht per person (starting at 1,000 baht for 4 persons).<br />
                                            - Limit to 2 Community Room Bookings per day per 1 LINE user account. (In case of cancellation,  such booking right shall be forfeited.)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative mt-2">
                                <div className="row text-center mt-1 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/Booking" className="btn btn-lg mb-0 btn-register-2">Next</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}
