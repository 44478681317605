import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'

const liff = window.liff;

export class BookingStep2 extends Component {
    static displayName = BookingStep2.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            ModelCssinner03: "d-none",
            jpage: "",
            userLineID: "",
            selectedstore: this.props.location.state.selectedstore,
            selecteddate: this.props.location.state.selecteddate,
            roomlist: [],
            totalroom: 0,
            selectroom: "",
            cssroom0: "btn btn-normal btn-outline-white d-none",
            cssroom1: "btn btn-normal btn-outline-white d-none",
            cssroom2: "btn btn-normal btn-outline-white d-none",
            cssroom3: "btn btn-normal btn-outline-white d-none",
            cssroom4: "btn btn-normal btn-outline-white d-none",
            timestart: "",
            timeend: "",
            maxhour: 0,
            timeOpen: "",
            timeClose: ""
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSelectRoom0 = this.onSelectRoom0.bind(this);
        this.onSelectRoom1 = this.onSelectRoom1.bind(this);
        this.onSelectRoom2 = this.onSelectRoom2.bind(this);
        this.onSelectRoom3 = this.onSelectRoom3.bind(this);
        this.onSelectRoom4 = this.onSelectRoom4.bind(this);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            ModelCssinner03: "d-none"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.selectroom) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.timestart) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.timeend) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Good to go!!

            //Check total hour
            const maxmin = this.state.maxhour * 60;
            const date0 = new Date().setHours(this.state.timestart.slice(0, 2), this.state.timestart.slice(-2));
            const date1 = new Date().setHours(this.state.timeend.slice(0, 2), this.state.timeend.slice(-2));

            var diffMins = Math.round((date1 - date0) / 60000);
            if (diffMins > maxmin || diffMins <= 0) {
                //More than max min or time end > time start
                this.setState({
                    ModelCssinner03: "",
                    ModelCss01: "popup"
                });
            }
            else {
                //-> push to step3
                var thisroomid = this.state.roomlist[this.state.selectroom].roomId;
                var thisroomname = this.state.roomlist[this.state.selectroom].roomName;
                var thisroommaxguest = this.state.roomlist[this.state.selectroom].maxGuestTxt;
                var thisroomstoreaddr = this.state.roomlist[this.state.selectroom].storeAddr;

                this.props.history.push({
                    pathname: "/BookingStep3",
                    state: {
                        selectedstore: this.state.selectedstore,
                        selecteddate: this.state.selecteddate,
                        selectroom: this.state.selectroom,
                        selectroomid: thisroomid,
                        selectroomname: thisroomname,
                        selectroommaxguest: thisroommaxguest,
                        thisroomstoreaddr: thisroomstoreaddr,
                        timestart: this.state.timestart,
                        timeend: this.state.timeend
                    }
                });
            }
        }
    }

    async onSelectRoom0() {
        this.setState({
            selectroom: "0",
            maxhour: this.state.roomlist[0].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    async onSelectRoom1() {
        this.setState({
            selectroom: "1",
            maxhour: this.state.roomlist[1].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    async onSelectRoom2() {
        this.setState({
            selectroom: "2",
            maxhour: this.state.roomlist[2].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white room-active",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white room-active",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white room-active",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    async onSelectRoom3() {
        this.setState({
            selectroom: "3",
            maxhour: this.state.roomlist[3].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white room-active",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    async onSelectRoom4() {
        this.setState({
            selectroom: "4",
            maxhour: this.state.roomlist[4].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white room-active"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "x1";
            //let LineuserPicture = "";                                                            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end

                    //retrive room list - start
                    const requestOptions3 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                Storename: this.state.selectedstore,
                                selecteddate: this.state.selecteddate
                            }
                        )
                    };
                    await fetch('/api/roomlist', requestOptions3)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                roomlist: json,
                                totalroom: json.length
                            });
                        });

                    //Show room for select
                    var roomtotal = this.state.roomlist.length - 1;
                    switch (roomtotal) {
                        case 0:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white d-none",
                                cssroom2: "btn btn-normal btn-outline-white d-none",
                                cssroom3: "btn btn-normal btn-outline-white d-none",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 1:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white d-none",
                                cssroom3: "btn btn-normal btn-outline-white d-none",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 2:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white",
                                cssroom3: "btn btn-normal btn-outline-white d-none",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 3:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white",
                                cssroom3: "btn btn-normal btn-outline-white",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 4:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white",
                                cssroom3: "btn btn-normal btn-outline-white",
                                cssroom4: "btn btn-normal btn-outline-white"
                            });
                            break;
                        default:
                            break;
                    }
                    //retrive room list - end


                    //OverRide select room 0 on load - start
                    this.setState({
                        selectroom: "0",
                        maxhour: this.state.roomlist[0].maxHour,
                        timeOpen: this.state.roomlist[0].timeOpen,
                        timeClose: this.state.roomlist[0].timeClose,
                        cssroom0: "btn btn-normal btn-outline-white room-active"
                    });
                    //OverRide select room 0 on load - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            var selectedstore = this.state.selectedstore;

            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            const dateoptions = {
                weekday: "short", day: "numeric", month: "short", year: "numeric"
            };

            var selecteddate = "";
            switch (this.state.selecteddate) {
                case "D0":
                    selecteddate = date0.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D1":
                    selecteddate = date1.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D2":
                    selecteddate = date2.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D3":
                    selecteddate = date3.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D4":
                    selecteddate = date4.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D5":
                    selecteddate = date5.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D6":
                    selecteddate = date6.toLocaleDateString('en-GB', dateoptions);
                    break;
                default:
                    break;
            }
            //Date list - end                       

            //Room list - start                        
            var roomname0 = "";
            var roomname1 = "";
            var roomname2 = "";
            var roomname3 = "";
            var roomname4 = "";
            var roommaxguest0 = "";
            var roommaxguest1 = "";
            var roommaxguest2 = "";
            var roommaxguest3 = "";
            var roommaxguest4 = "";
            for (let i = 0; i < this.state.roomlist.length; i++) {
                switch (i) {
                    case 0:
                        roommaxguest0 = this.state.roomlist[i].maxGuestTxt;
                        roomname0 = this.state.roomlist[i].roomName; break;
                    case 1:
                        roommaxguest1 = this.state.roomlist[i].maxGuestTxt;
                        roomname1 = this.state.roomlist[i].roomName; break;
                    case 2:
                        roommaxguest2 = this.state.roomlist[i].maxGuestTxt;
                        roomname2 = this.state.roomlist[i].roomName; break;
                    case 3:
                        roommaxguest3 = this.state.roomlist[i].maxGuestTxt;
                        roomname3 = this.state.roomlist[i].roomName; break;
                    case 4:
                        roommaxguest4 = this.state.roomlist[i].maxGuestTxt;
                        roomname4 = this.state.roomlist[i].roomName; break;
                    default: break;
                }
            }
            //Room list - end

            //Time list - start
            var cssStart1 = "";
            var cssStart2 = "";
            var cssStart3 = "";
            var cssStart4 = "";
            var cssStart5 = "";
            var cssStart6 = "";
            var cssStart7 = "";
            var cssStart8 = "";
            var cssStart9 = "";
            var cssStart10 = "";
            var cssStart11 = "";
            var cssStart12 = "";
            var cssStart13 = "";
            var cssStart14 = "";
            var cssStart15 = "";
            var cssStart16 = "";
            var cssStart17 = "";
            var cssStart18 = "";
            var cssStart19 = "";
            var cssStart20 = "";
            var cssStart21 = "";
            var cssStart22 = "";
            var cssStart23 = "";
            var cssStart24 = "";
            var cssStart25 = "";
            var cssStart26 = "";
            var cssStart27 = "";
            var cssStart28 = "";
            var cssStart29 = "";
            var cssStart30 = "";
            var cssStart31 = "";
            var cssStart32 = "";
            var cssStart33 = "";
            var cssStart34 = "";
            var cssStart35 = "";
            var cssStart36 = "";
            var cssStart37 = "";
            var cssStart38 = "";
            var cssStart39 = "";
            var cssStart40 = "";
            var cssStart41 = "";
            var cssStart42 = "";
            var cssStart43 = "";
            var cssStart44 = "";
            var cssStart45 = "";
            var cssStart46 = "";
            var cssStart47 = "";
            var cssStart48 = "";

            var cssEnd1 = "";
            var cssEnd2 = "";
            var cssEnd3 = "";
            var cssEnd4 = "";
            var cssEnd5 = "";
            var cssEnd6 = "";
            var cssEnd7 = "";
            var cssEnd8 = "";
            var cssEnd9 = "";
            var cssEnd10 = "";
            var cssEnd11 = "";
            var cssEnd12 = "";
            var cssEnd13 = "";
            var cssEnd14 = "";
            var cssEnd15 = "";
            var cssEnd16 = "";
            var cssEnd17 = "";
            var cssEnd18 = "";
            var cssEnd19 = "";
            var cssEnd20 = "";
            var cssEnd21 = "";
            var cssEnd22 = "";
            var cssEnd23 = "";
            var cssEnd24 = "";
            var cssEnd25 = "";
            var cssEnd26 = "";
            var cssEnd27 = "";
            var cssEnd28 = "";
            var cssEnd29 = "";
            var cssEnd30 = "";
            var cssEnd31 = "";
            var cssEnd32 = "";
            var cssEnd33 = "";
            var cssEnd34 = "";
            var cssEnd35 = "";
            var cssEnd36 = "";
            var cssEnd37 = "";
            var cssEnd38 = "";
            var cssEnd39 = "";
            var cssEnd40 = "";
            var cssEnd41 = "";
            var cssEnd42 = "";
            var cssEnd43 = "";
            var cssEnd44 = "";
            var cssEnd45 = "";
            var cssEnd46 = "";
            var cssEnd47 = "";
            var cssEnd48 = "";


            if (this.state.timeOpen === "00:00" && this.state.timeClose === "00:00") {
                //Do nothing Open 24Hr
            }
            else {
                //Not Open 24Hr
                switch (this.state.timeOpen) {
                    case "00:30":
                        cssStart1 = "d-none";

                        cssEnd1 = "d-none";
                        break;
                    case "01:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none";

                        cssStart1 = "d-none"; cssEnd2 = "d-none";
                        break;
                    case "01:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none";
                        break;
                    case "02:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        break;
                    case "02:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none";
                        break;
                    case "03:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none";
                        break;
                    case "03:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none";
                        break;
                    case "04:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        break;
                    case "04:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none";
                        break;
                    case "05:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none";
                        break;
                    case "05:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none";
                        break;
                    case "06:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        break;
                    case "06:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none";
                        break;
                    case "07:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none";
                        break;
                    case "07:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none";
                        break;
                    case "08:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        break;
                    case "08:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none";
                        break;
                    case "09:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none";
                        break;
                    case "09:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none";
                        break;
                    case "10:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        break;
                    case "10:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none";
                        break;
                    case "11:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none";
                        break;
                    case "11:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none";
                        break;
                    case "12:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        break;
                    case "12:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none";
                        break;
                    case "13:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none";
                        break;
                    case "13:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none";
                        break;
                    case "14:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        break;
                    case "14:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none";
                        break;
                    case "15:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none";
                        break;
                    case "15:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none";
                        break;
                    case "16:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        break;
                    case "16:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none";
                        break;
                    case "17:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none";
                        break;
                    case "17:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none";
                        break;
                    case "18:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        break;
                    case "18:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none";
                        break;
                    case "19:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none";
                        break;
                    case "19:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none";
                        break;
                    case "20:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        break;
                    case "20:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none";
                        break;
                    case "21:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none"; cssStart42 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none"; cssEnd42 = "d-none";
                        break;
                    case "21:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none"; cssStart42 = "d-none"; cssStart43 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none"; cssEnd42 = "d-none"; cssEnd43 = "d-none";
                        break;
                    case "22:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none"; cssStart42 = "d-none"; cssStart43 = "d-none"; cssStart44 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none"; cssEnd42 = "d-none"; cssEnd43 = "d-none"; cssEnd44 = "d-none";
                        break;
                    case "22:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none"; cssStart42 = "d-none"; cssStart43 = "d-none"; cssStart44 = "d-none";
                        cssStart45 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none"; cssEnd42 = "d-none"; cssEnd43 = "d-none"; cssEnd44 = "d-none";
                        cssEnd45 = "d-none";
                        break;
                    case "23:00":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none"; cssStart42 = "d-none"; cssStart43 = "d-none"; cssStart44 = "d-none";
                        cssStart45 = "d-none"; cssStart46 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none"; cssEnd42 = "d-none"; cssEnd43 = "d-none"; cssEnd44 = "d-none";
                        cssEnd45 = "d-none"; cssEnd46 = "d-none";
                        break;
                    case "23:30":
                        cssStart1 = "d-none"; cssStart2 = "d-none"; cssStart3 = "d-none"; cssStart4 = "d-none";
                        cssStart5 = "d-none"; cssStart6 = "d-none"; cssStart7 = "d-none"; cssStart8 = "d-none";
                        cssStart9 = "d-none"; cssStart10 = "d-none"; cssStart11 = "d-none"; cssStart12 = "d-none";
                        cssStart13 = "d-none"; cssStart14 = "d-none"; cssStart15 = "d-none"; cssStart16 = "d-none";
                        cssStart17 = "d-none"; cssStart18 = "d-none"; cssStart19 = "d-none"; cssStart20 = "d-none";
                        cssStart21 = "d-none"; cssStart22 = "d-none"; cssStart23 = "d-none"; cssStart24 = "d-none";
                        cssStart25 = "d-none"; cssStart26 = "d-none"; cssStart27 = "d-none"; cssStart28 = "d-none";
                        cssStart29 = "d-none"; cssStart30 = "d-none"; cssStart31 = "d-none"; cssStart32 = "d-none";
                        cssStart33 = "d-none"; cssStart34 = "d-none"; cssStart35 = "d-none"; cssStart36 = "d-none";
                        cssStart37 = "d-none"; cssStart38 = "d-none"; cssStart39 = "d-none"; cssStart40 = "d-none";
                        cssStart41 = "d-none"; cssStart42 = "d-none"; cssStart43 = "d-none"; cssStart44 = "d-none";
                        cssStart45 = "d-none"; cssStart46 = "d-none"; cssStart47 = "d-none";

                        cssEnd1 = "d-none"; cssEnd2 = "d-none"; cssEnd3 = "d-none"; cssEnd4 = "d-none";
                        cssEnd5 = "d-none"; cssEnd6 = "d-none"; cssEnd7 = "d-none"; cssEnd8 = "d-none";
                        cssEnd9 = "d-none"; cssEnd10 = "d-none"; cssEnd11 = "d-none"; cssEnd12 = "d-none";
                        cssEnd13 = "d-none"; cssEnd14 = "d-none"; cssEnd15 = "d-none"; cssEnd16 = "d-none";
                        cssEnd17 = "d-none"; cssEnd18 = "d-none"; cssEnd19 = "d-none"; cssEnd20 = "d-none";
                        cssEnd21 = "d-none"; cssEnd22 = "d-none"; cssEnd23 = "d-none"; cssEnd24 = "d-none";
                        cssEnd25 = "d-none"; cssEnd26 = "d-none"; cssEnd27 = "d-none"; cssEnd28 = "d-none";
                        cssEnd29 = "d-none"; cssEnd30 = "d-none"; cssEnd31 = "d-none"; cssEnd32 = "d-none";
                        cssEnd33 = "d-none"; cssEnd34 = "d-none"; cssEnd35 = "d-none"; cssEnd36 = "d-none";
                        cssEnd37 = "d-none"; cssEnd38 = "d-none"; cssEnd39 = "d-none"; cssEnd40 = "d-none";
                        cssEnd41 = "d-none"; cssEnd42 = "d-none"; cssEnd43 = "d-none"; cssEnd44 = "d-none";
                        cssEnd45 = "d-none"; cssEnd46 = "d-none"; cssEnd47 = "d-none";
                        break;
                    default: break;
                }
                
                switch (this.state.timeClose) {
                    case "23:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none";
                        break;
                    case "23:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none";
                        break;
                    case "22:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; 

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        break;
                    case "22:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none";
                        break;
                    case "21:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none";
                        break;
                    case "21:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none";
                        break;
                    case "20:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        break;
                    case "20:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none";
                        break;
                    case "19:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none";
                        break;                    
                    case "19:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none";
                        break;
                    case "18:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        break;
                    case "18:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none";
                        break;
                    case "17:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none"; cssStart33 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none"; cssEnd35 = "d-none";
                        break;
                    case "17:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none"; cssStart33 = "d-none";
                        cssStart32 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none"; cssEnd35 = "d-none"; cssEnd34 = "d-none";
                        break;
                    case "16:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none"; cssStart33 = "d-none";
                        cssStart32 = "d-none"; cssStart31 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none"; cssEnd35 = "d-none"; cssEnd34 = "d-none"; cssEnd33 = "d-none";
                        break;
                    case "16:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none"; cssStart33 = "d-none";
                        cssStart32 = "d-none"; cssStart31 = "d-none"; cssStart30 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none"; cssEnd35 = "d-none"; cssEnd34 = "d-none"; cssEnd33 = "d-none";
                        cssEnd32 = "d-none";
                        break;
                    case "15:30":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none"; cssStart33 = "d-none";
                        cssStart32 = "d-none"; cssStart31 = "d-none"; cssStart30 = "d-none"; cssStart29 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none"; cssEnd35 = "d-none"; cssEnd34 = "d-none"; cssEnd33 = "d-none";
                        cssEnd32 = "d-none"; cssEnd31 = "d-none";
                        break;
                    case "15:00":
                        cssStart48 = "d-none"; cssStart47 = "d-none"; cssStart46 = "d-none"; cssStart45 = "d-none";
                        cssStart44 = "d-none"; cssStart43 = "d-none"; cssStart42 = "d-none"; cssStart41 = "d-none";
                        cssStart40 = "d-none"; cssStart39 = "d-none"; cssStart38 = "d-none"; cssStart37 = "d-none";
                        cssStart36 = "d-none"; cssStart35 = "d-none"; cssStart34 = "d-none"; cssStart33 = "d-none";
                        cssStart32 = "d-none"; cssStart31 = "d-none"; cssStart30 = "d-none"; cssStart29 = "d-none";
                        cssStart28 = "d-none";

                        cssEnd48 = "d-none"; cssEnd47 = "d-none"; cssEnd46 = "d-none"; cssEnd45 = "d-none";
                        cssEnd44 = "d-none"; cssEnd43 = "d-none"; cssEnd42 = "d-none"; cssEnd41 = "d-none";
                        cssEnd40 = "d-none"; cssEnd39 = "d-none"; cssEnd38 = "d-none"; cssEnd37 = "d-none";
                        cssEnd36 = "d-none"; cssEnd35 = "d-none"; cssEnd34 = "d-none"; cssEnd33 = "d-none";
                        cssEnd32 = "d-none"; cssEnd31 = "d-none"; cssEnd30 = "d-none";
                        break;
                    default: break;
                }
            }

            var timeStartlist = [];
            timeStartlist.push(<option key="0" value="" className="d-none"></option>)
            timeStartlist.push(<option key="1" value="00:00" className={cssStart1}>00:00</option>)
            timeStartlist.push(<option key="2" value="00:30" className={cssStart2}>00:30</option>)
            timeStartlist.push(<option key="3" value="01:00" className={cssStart3}>01:00</option>)
            timeStartlist.push(<option key="4" value="01:30" className={cssStart4}>01:30</option>)
            timeStartlist.push(<option key="5" value="02:00" className={cssStart5}>02:00</option>)
            timeStartlist.push(<option key="6" value="02:30" className={cssStart6}>02:30</option>)
            timeStartlist.push(<option key="7" value="03:00" className={cssStart7}>03:00</option>)
            timeStartlist.push(<option key="8" value="03:30" className={cssStart8}>03:30</option>)
            timeStartlist.push(<option key="9" value="04:00" className={cssStart9}>04:00</option>)
            timeStartlist.push(<option key="10" value="04:30" className={cssStart10}>04:30</option>)
            timeStartlist.push(<option key="11" value="05:00" className={cssStart11}>05:00</option>)
            timeStartlist.push(<option key="12" value="05:30" className={cssStart12}>05:30</option>)
            timeStartlist.push(<option key="13" value="06:00" className={cssStart13}>06:00</option>)
            timeStartlist.push(<option key="14" value="06:30" className={cssStart14}>06:30</option>)
            timeStartlist.push(<option key="15" value="07:00" className={cssStart15}>07:00</option>)
            timeStartlist.push(<option key="16" value="07:30" className={cssStart16}>07:30</option>)
            timeStartlist.push(<option key="17" value="08:00" className={cssStart17}>08:00</option>)
            timeStartlist.push(<option key="18" value="08:30" className={cssStart18}>08:30</option>)
            timeStartlist.push(<option key="19" value="09:00" className={cssStart19}>09:00</option>)
            timeStartlist.push(<option key="20" value="09:30" className={cssStart20}>09:30</option>)
            timeStartlist.push(<option key="21" value="10:00" className={cssStart21}>10:00</option>)
            timeStartlist.push(<option key="22" value="10:30" className={cssStart22}>10:30</option>)
            timeStartlist.push(<option key="23" value="11:00" className={cssStart23}>11:00</option>)
            timeStartlist.push(<option key="24" value="11:30" className={cssStart24}>11:30</option>)
            timeStartlist.push(<option key="25" value="12:00" className={cssStart25}>12:00</option>)
            timeStartlist.push(<option key="26" value="12:30" className={cssStart26}>12:30</option>)
            timeStartlist.push(<option key="27" value="13:00" className={cssStart27}>13:00</option>)
            timeStartlist.push(<option key="28" value="13:30" className={cssStart28}>13:30</option>)
            timeStartlist.push(<option key="29" value="14:00" className={cssStart29}>14:00</option>)
            timeStartlist.push(<option key="30" value="14:30" className={cssStart30}>14:30</option>)
            timeStartlist.push(<option key="31" value="15:00" className={cssStart31}>15:00</option>)
            timeStartlist.push(<option key="32" value="15:30" className={cssStart32}>15:30</option>)
            timeStartlist.push(<option key="33" value="16:00" className={cssStart33}>16:00</option>)
            timeStartlist.push(<option key="34" value="16:30" className={cssStart34}>16:30</option>)
            timeStartlist.push(<option key="35" value="17:00" className={cssStart35}>17:00</option>)
            timeStartlist.push(<option key="36" value="17:30" className={cssStart36}>17:30</option>)
            timeStartlist.push(<option key="37" value="18:00" className={cssStart37}>18:00</option>)
            timeStartlist.push(<option key="38" value="18:30" className={cssStart38}>18:30</option>)
            timeStartlist.push(<option key="39" value="19:00" className={cssStart39}>19:00</option>)
            timeStartlist.push(<option key="40" value="19:30" className={cssStart40}>19:30</option>)
            timeStartlist.push(<option key="41" value="20:00" className={cssStart41}>20:00</option>)
            timeStartlist.push(<option key="42" value="20:30" className={cssStart42}>20:30</option>)
            timeStartlist.push(<option key="43" value="21:00" className={cssStart43}>21:00</option>)
            timeStartlist.push(<option key="44" value="21:30" className={cssStart44}>21:30</option>)
            timeStartlist.push(<option key="45" value="22:00" className={cssStart45}>22:00</option>)
            timeStartlist.push(<option key="46" value="22:30" className={cssStart46}>22:30</option>)
            timeStartlist.push(<option key="47" value="23:00" className={cssStart47}>23:00</option>)
            timeStartlist.push(<option key="48" value="23:30" className={cssStart48}>23:30</option>)

            var timeEndlist = [];
            timeEndlist.push(<option key="0" value="" className="d-none"></option>)
            timeEndlist.push(<option key="1" value="00:00" className={cssEnd1}>00:00</option>)
            timeEndlist.push(<option key="2" value="00:30" className={cssEnd2}>00:30</option>)
            timeEndlist.push(<option key="3" value="01:00" className={cssEnd3}>01:00</option>)
            timeEndlist.push(<option key="4" value="01:30" className={cssEnd4}>01:30</option>)
            timeEndlist.push(<option key="5" value="02:00" className={cssEnd5}>02:00</option>)
            timeEndlist.push(<option key="6" value="02:30" className={cssEnd6}>02:30</option>)
            timeEndlist.push(<option key="7" value="03:00" className={cssEnd7}>03:00</option>)
            timeEndlist.push(<option key="8" value="03:30" className={cssEnd8}>03:30</option>)
            timeEndlist.push(<option key="9" value="04:00" className={cssEnd9}>04:00</option>)
            timeEndlist.push(<option key="10" value="04:30" className={cssEnd10}>04:30</option>)
            timeEndlist.push(<option key="11" value="05:00" className={cssEnd11}>05:00</option>)
            timeEndlist.push(<option key="12" value="05:30" className={cssEnd12}>05:30</option>)
            timeEndlist.push(<option key="13" value="06:00" className={cssEnd13}>06:00</option>)
            timeEndlist.push(<option key="14" value="06:30" className={cssEnd14}>06:30</option>)
            timeEndlist.push(<option key="15" value="07:00" className={cssEnd15}>07:00</option>)
            timeEndlist.push(<option key="16" value="07:30" className={cssEnd16}>07:30</option>)
            timeEndlist.push(<option key="17" value="08:00" className={cssEnd17}>08:00</option>)
            timeEndlist.push(<option key="18" value="08:30" className={cssEnd18}>08:30</option>)
            timeEndlist.push(<option key="19" value="09:00" className={cssEnd19}>09:00</option>)
            timeEndlist.push(<option key="20" value="09:30" className={cssEnd20}>09:30</option>)
            timeEndlist.push(<option key="21" value="10:00" className={cssEnd21}>10:00</option>)
            timeEndlist.push(<option key="22" value="10:30" className={cssEnd22}>10:30</option>)
            timeEndlist.push(<option key="23" value="11:00" className={cssEnd23}>11:00</option>)
            timeEndlist.push(<option key="24" value="11:30" className={cssEnd24}>11:30</option>)
            timeEndlist.push(<option key="25" value="12:00" className={cssEnd25}>12:00</option>)
            timeEndlist.push(<option key="26" value="12:30" className={cssEnd26}>12:30</option>)
            timeEndlist.push(<option key="27" value="13:00" className={cssEnd27}>13:00</option>)
            timeEndlist.push(<option key="28" value="13:30" className={cssEnd28}>13:30</option>)
            timeEndlist.push(<option key="29" value="14:00" className={cssEnd29}>14:00</option>)
            timeEndlist.push(<option key="30" value="14:30" className={cssEnd30}>14:30</option>)
            timeEndlist.push(<option key="31" value="15:00" className={cssEnd31}>15:00</option>)
            timeEndlist.push(<option key="32" value="15:30" className={cssEnd32}>15:30</option>)
            timeEndlist.push(<option key="33" value="16:00" className={cssEnd33}>16:00</option>)
            timeEndlist.push(<option key="34" value="16:30" className={cssEnd34}>16:30</option>)
            timeEndlist.push(<option key="35" value="17:00" className={cssEnd35}>17:00</option>)
            timeEndlist.push(<option key="36" value="17:30" className={cssEnd36}>17:30</option>)
            timeEndlist.push(<option key="37" value="18:00" className={cssEnd37}>18:00</option>)
            timeEndlist.push(<option key="38" value="18:30" className={cssEnd38}>18:30</option>)
            timeEndlist.push(<option key="39" value="19:00" className={cssEnd39}>19:00</option>)
            timeEndlist.push(<option key="40" value="19:30" className={cssEnd40}>19:30</option>)
            timeEndlist.push(<option key="41" value="20:00" className={cssEnd41}>20:00</option>)
            timeEndlist.push(<option key="42" value="20:30" className={cssEnd42}>20:30</option>)
            timeEndlist.push(<option key="43" value="21:00" className={cssEnd43}>21:00</option>)
            timeEndlist.push(<option key="44" value="21:30" className={cssEnd44}>21:30</option>)
            timeEndlist.push(<option key="45" value="22:00" className={cssEnd45}>22:00</option>)
            timeEndlist.push(<option key="46" value="22:30" className={cssEnd46}>22:30</option>)
            timeEndlist.push(<option key="47" value="23:00" className={cssEnd47}>23:00</option>)
            timeEndlist.push(<option key="48" value="23:30" className={cssEnd48}>23:30</option>)
            //Time list - end


            var maxHour = this.state.maxhour;
        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <div className="form-enter p-3 pt-0 mt-4">
                                    <div className="row pb-0">
                                        <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                                        <div className="col-10"><p className="text-green mb-0 fontkanit">{selectedstore}</p></div>
                                    </div>
                                    <div className="row pb-0">
                                        <div className="col-2"><p className="fontsodo-semibold mb-0">Date</p></div>
                                        <div className="col-8"><p className="text-green mb-0 fontkanit">{selecteddate}</p></div>
                                    </div>
                                    <h2 className="h5 text-center pt-3 mb-0 fontsodo-bold">Select Room</h2>
                                    <h3 className="h6 text-center mb-2 fontkanit">เลือกห้องประชุม</h3>
                                    <div className="row justify-content-center mb-4">
                                        <div className="col-8 text-center"><img src={require("./media/dot-page2.png")} alt="Page2" /></div>
                                    </div>
                                    <div className="d-grid gap-2 mb-5">
                                        <span className={this.state.cssroom0} onClick={this.onSelectRoom0}>{roomname0} <br /><small>For {roommaxguest0} people</small></span>
                                        <span className={this.state.cssroom1} onClick={this.onSelectRoom1}>{roomname1} <br /><small>For {roommaxguest1} people</small></span>
                                        <span className={this.state.cssroom2} onClick={this.onSelectRoom2}>{roomname2} <br /><small>For {roommaxguest2} people</small></span>
                                        <span className={this.state.cssroom3} onClick={this.onSelectRoom3}>{roomname3} <br /><small>For {roommaxguest3} people</small></span>
                                        <span className={this.state.cssroom4} onClick={this.onSelectRoom4}>{roomname4} <br /><small>For {roommaxguest4} people</small></span>
                                    </div>
                                    <h2 className="h5 text-center pt-3 mb-0 fontsodo-bold">Select Time</h2>
                                    <h3 className="h6 text-center mb-2 fontkanit">เวลาในการเข้าใช้ห้องประชุม</h3>
                                    <div className="form-group mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="fontsodo-semibold lh-sm pb-2"><span className="text-green">Start</span></label>
                                                <select className="form-control" required name="timestart" id="timestart" value={this.state.timestart} onChange={this.onInputchange}>
                                                    {timeStartlist}
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <label className="fontsodo-semibold lh-sm pb-2"><span className="text-housegreen">End</span></label>
                                                <select className="form-control" required name="timeend" id="timeend" value={this.state.timeend} onChange={this.onInputchange}>
                                                    {timeEndlist}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col"><p className="fontsodo-semibold">Maximum {maxHour} hours per booking</p></div>
                                        </div>
                                        <div className="row justify-content-center mt-2 gx-3">
                                            <div className="col-5 text-end"><span className="date-text-select" style={{ height: '20px', display: 'inline-block', marginBottom: '-5px' }} /> <small className="fontsodo-semibold">Available</small></div>
                                            <div className="col-5 text-start"><span className="date-text-select date-unactive" style={{ height: '20px', display: 'inline-block', marginBottom: '-5px' }} /> <small className="fontsodo-semibold">Unavailable</small></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer-button position-relative">
                                    <div className="row text-center mt-3 mb-0 justify-content-center">
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <Link to="/Booking" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <span className="btn btn-lg mb-0 btn-register-2" onClick={this.onSubmitForm}>Next</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {/* Custom Pop up */}
                                    <div id="popup1" className={this.state.ModelCss01}>
                                        <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                        <div className="text-center">
                                            <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                            <strong>และครบถ้วน</strong>
                                        </div>
                                        <ul className="text-start">
                                            <li className={this.state.ModelCssinner01}>กรุณาเลือกห้องประชุม</li>
                                            <li className={this.state.ModelCssinner02}>กรุณาเลือกเวลาที่ต้องการจอง</li>
                                            <li className={this.state.ModelCssinner03}>กรุณาตรวจสอบเวลาที่จอง</li>
                                        </ul>
                                        <div className="text-center mt-3 mb-2">
                                            <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }                               
            </div>
        );
    }
}
