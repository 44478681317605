import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'

const liff = window.liff;
const maxperday = 2;

export class Booking extends Component {
    static displayName = Booking.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            jpage: "",
            userLineID: "",
            searchstore: "",
            provincelist: [],
            province: "",
            amphurlist: [],
            amphur: "",
            storelist: [],
            store: "",
            cssDate0: "date-text-select",
            cssDate1: "date-text-select",
            cssDate2: "date-text-select",
            cssDate3: "date-text-select",
            cssDate4: "date-text-select",
            cssDate5: "date-text-select",
            cssDate6: "date-text-select",
            dateSelect: "",
            storeSelect: "",
            displayB01: "form-group mb-4 d-block",
            displayB02: "form-group mb-4 d-none",
            d0booked: 0,
            d1booked: 0,
            d2booked: 0,
            d3booked: 0,
            d4booked: 0,
            d5booked: 0,
            d6booked: 0
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSelectDate0 = this.onSelectDate0.bind(this);
        this.onSelectDate1 = this.onSelectDate1.bind(this);
        this.onSelectDate2 = this.onSelectDate2.bind(this);
        this.onSelectDate3 = this.onSelectDate3.bind(this);
        this.onSelectDate4 = this.onSelectDate4.bind(this);
        this.onSelectDate5 = this.onSelectDate5.bind(this);
        this.onSelectDate6 = this.onSelectDate6.bind(this);

        this.closeModal = this.closeModal.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);
        this.switchBlock01 = this.switchBlock01.bind(this);
        this.switchBlock02 = this.switchBlock02.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none"
        });
    }

    closeModal2() {
        this.setState({
            ModelCss02: "popuphide"
        });
    }

    switchBlock01() {
        this.setState({
            displayB01: "form-group mb-4 d-block",
            displayB02: "form-group mb-4 d-none"
        });
    }

    switchBlock02() {
        this.setState({
            displayB01: "form-group mb-4 d-none",
            displayB02: "form-group mb-4 d-block"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        if (name === "province" && value != null) {
            //reset autocomplete search
            window.$("#searchstore").val("");
            window.$("#storenametop").text("สาขาที่เลือก");

            //retrive amphur list - start
            const requestOptions3 = {
                method: 'GET'
            };
            fetch('/api/storedistrict/?Province=' + value, requestOptions3)
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        amphurlist: json
                    });
                });
            //retrive amphur list - end

            //reset this store
            //retrive store list - start
            const requestOptions4 = {
                method: 'GET'
            };
            fetch('/api/storesearchbypd/?Province=&District=', requestOptions4)
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        storelist: json
                    });
                });
            //retrive store list - end
        }

        if (name === "amphur" && value != null) {
            //reset autocomplete search
            window.$("#searchstore").val("");
            window.$("#storenametop").text("สาขาที่เลือก");

            //retrive store list - start
            const requestOptions4 = {
                method: 'GET'
            };
            fetch('/api/storesearchbypd/?Province=' + this.state.province + '&District=' + value, requestOptions4)
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        storelist: json
                    });
                });
            //retrive store list - end
        }

        if (name === "store" && value != null) {
            //reset autocomplete search
            window.$("#searchstore").val("");
            window.$("#storenametop").text("สาขาที่เลือก");

            window.$("#storenametop").text(value);
        }
    }

    async onSubmitForm() {
        let isthisvalid = "yes";
        let selectedstore = window.$("#storenametop").text();

        if (selectedstore === "" || selectedstore === "สาขาที่เลือก") {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.dateSelect) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Good to go!!

            //check fully book on that day - start
            const requestOptions3 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        Storename: selectedstore,
                        selecteddate: this.state.dateSelect
                    }
                )
            };
            await fetch('/api/BookingCheckFully', requestOptions3)
                .then(response => response.json())
                .then(json => {
                    if (json.isFullyBook === "Yes") {
                        this.setState({
                            ModelCss02: "popup"
                        });
                    }
                    else {
                        //-> push to step2
                        this.props.history.push({
                            pathname: "/BookingStep2V2",
                            state: {
                                selectedstore: selectedstore,
                                selecteddate: this.state.dateSelect
                            }
                        });
                    }
                });
            //check fully book on that day - end            
        }
    }

    async onSelectDate0() {
        if (this.state.d0booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select date-active",
                cssDate1: "date-text-select",
                cssDate2: "date-text-select",
                cssDate3: "date-text-select",
                cssDate4: "date-text-select",
                cssDate5: "date-text-select",
                cssDate6: "date-text-select",
                dateSelect: "D0"
            });
        }
    }

    async onSelectDate1() {
        if (this.state.d1booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select",
                cssDate1: "date-text-select date-active",
                cssDate2: "date-text-select",
                cssDate3: "date-text-select",
                cssDate4: "date-text-select",
                cssDate5: "date-text-select",
                cssDate6: "date-text-select",
                dateSelect: "D1"
            });
        }
    }

    async onSelectDate2() {
        if (this.state.d2booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select",
                cssDate1: "date-text-select",
                cssDate2: "date-text-select date-active",
                cssDate3: "date-text-select",
                cssDate4: "date-text-select",
                cssDate5: "date-text-select",
                cssDate6: "date-text-select",
                dateSelect: "D2"
            });
        }
    }

    async onSelectDate3() {
        if (this.state.d3booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select",
                cssDate1: "date-text-select",
                cssDate2: "date-text-select",
                cssDate3: "date-text-select date-active",
                cssDate4: "date-text-select",
                cssDate5: "date-text-select",
                cssDate6: "date-text-select",
                dateSelect: "D3"
            });
        }
    }

    async onSelectDate4() {
        if (this.state.d4booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select",
                cssDate1: "date-text-select",
                cssDate2: "date-text-select",
                cssDate3: "date-text-select",
                cssDate4: "date-text-select date-active",
                cssDate5: "date-text-select",
                cssDate6: "date-text-select",
                dateSelect: "D4"
            });
        }
    }

    async onSelectDate5() {
        if (this.state.d5booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select",
                cssDate1: "date-text-select",
                cssDate2: "date-text-select",
                cssDate3: "date-text-select",
                cssDate4: "date-text-select",
                cssDate5: "date-text-select date-active",
                cssDate6: "date-text-select",
                dateSelect: "D5"
            });
        }
    }

    async onSelectDate6() {
        if (this.state.d6booked < maxperday) {
            //do action only if not max per day
            this.setState({
                cssDate0: "date-text-select",
                cssDate1: "date-text-select",
                cssDate2: "date-text-select",
                cssDate3: "date-text-select",
                cssDate4: "date-text-select",
                cssDate5: "date-text-select",
                cssDate6: "date-text-select date-active",
                dateSelect: "D6"
            });
        }
    }

    componentDidMount() {
        this.populateProfile();

        window.$("#storenametop").text("สาขาที่เลือก");
        window.$("#searchstore").autocomplete({
            minLength: 2,
            source: function (request, response) {
                window.$.ajax({
                    url: "/api/AutoCompleteStore",
                    type: "get",
                    dataType: "json",
                    data: { Prefix: request.term },
                    success: function (data) {
                        response(window.$.map(data, function (item) {
                            return { label: item.storename, value: item.storeid };
                        }))
                    }
                })
            },
            select: function (event, ui) {
                window.$("#storenametop").text(ui.item.label);
                window.$("#province").val(" Select Province ");
                window.$("#amphur").val(" Select Amphur ");
                window.$("#store").val(" Select Store ");
            }
        });
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "U0b81f50b7ae5779640000176e283010a";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId,
                                    d0booked: json.totalBookD0,
                                    d1booked: json.totalBookD1,
                                    d2booked: json.totalBookD2,
                                    d3booked: json.totalBookD3,
                                    d4booked: json.totalBookD4,
                                    d5booked: json.totalBookD5,
                                    d6booked: json.totalBookD6
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end

                    //retrive province list - start
                    const requestOptions2 = {
                        method: 'GET'
                    };
                    await fetch('/api/storeprovince', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                provincelist: json
                            });
                        });
                    //retrive province list - end

                    //retrive amphur list - start
                    const requestOptions3 = {
                        method: 'GET'
                    };
                    await fetch('/api/storedistrict/?Province=', requestOptions3)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                amphurlist: json
                            });
                        });
                    //retrive amphur list - end

                    //retrive store list - start
                    const requestOptions4 = {
                        method: 'GET'
                    };
                    await fetch('/api/storesearchbypd/?Province=&District=', requestOptions4)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                storelist: json
                            });
                        });
                    //retrive store list - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        var displayB01 = this.state.displayB01;
        var displayB02 = this.state.displayB02;

        if (this.state.jpage === "done") {
            var cssDate0 = this.state.cssDate0;
            var cssDate1 = this.state.cssDate1;
            var cssDate2 = this.state.cssDate2;
            var cssDate3 = this.state.cssDate3;
            var cssDate4 = this.state.cssDate4;
            var cssDate5 = this.state.cssDate5;
            var cssDate6 = this.state.cssDate6;

            //OverRide
            if (this.state.d0booked >= maxperday) { cssDate0 = "date-text-select date-unactive"; }
            if (this.state.d1booked >= maxperday) { cssDate1 = "date-text-select date-unactive"; }
            if (this.state.d2booked >= maxperday) { cssDate2 = "date-text-select date-unactive"; }
            if (this.state.d3booked >= maxperday) { cssDate3 = "date-text-select date-unactive"; }
            if (this.state.d4booked >= maxperday) { cssDate4 = "date-text-select date-unactive"; }
            if (this.state.d5booked >= maxperday) { cssDate5 = "date-text-select date-unactive"; }
            if (this.state.d6booked >= maxperday) { cssDate6 = "date-text-select date-unactive"; }

            //Dropdown province list - start
            var provincelist = [];
            for (let i = 0; i < this.state.provincelist.length; i++) {
                provincelist.push(
                    <option key={i} value={this.state.provincelist[i].province}>{this.state.provincelist[i].province}</option>
                )
            }
            //Dropdown province list - end

            //Dropdown amphur list - start
            var amphurlist = [];
            for (let i = 0; i < this.state.amphurlist.length; i++) {
                amphurlist.push(
                    <option key={i} value={this.state.amphurlist[i].district}>{this.state.amphurlist[i].district}</option>
                )
            }
            //Dropdown amphur list - end

            //Dropdown store list - start
            var storelist = [];
            for (let i = 0; i < this.state.storelist.length; i++) {
                storelist.push(
                    <option key={i} value={this.state.storelist[i].storename}>{this.state.storelist[i].storename}</option>
                )
            }
            //Dropdown store list - end

            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            //const formattedDate = date0.toLocaleDateString('en-GB', {
            //    day: 'numeric', month: 'short', year: 'numeric'
            //}).replace(/ /g, '-');

            const dateoptions = {
                day: "numeric"
            };

            const dateoptions2 = {
                month: 'short'
            };

            var date0showday = date0.toLocaleDateString('en-GB', dateoptions);
            var date0showmonth = date0.toLocaleDateString('en-GB', dateoptions2);
            var date1showday = date1.toLocaleDateString('en-GB', dateoptions);
            var date1showmonth = date1.toLocaleDateString('en-GB', dateoptions2);
            var date2showday = date2.toLocaleDateString('en-GB', dateoptions);
            var date2showmonth = date2.toLocaleDateString('en-GB', dateoptions2);
            var date3showday = date3.toLocaleDateString('en-GB', dateoptions);
            var date3showmonth = date3.toLocaleDateString('en-GB', dateoptions2);
            var date4showday = date4.toLocaleDateString('en-GB', dateoptions);
            var date4showmonth = date4.toLocaleDateString('en-GB', dateoptions2);
            var date5showday = date5.toLocaleDateString('en-GB', dateoptions);
            var date5showmonth = date5.toLocaleDateString('en-GB', dateoptions2);
            var date6showday = date6.toLocaleDateString('en-GB', dateoptions);
            var date6showmonth = date6.toLocaleDateString('en-GB', dateoptions2);
            //Date list - end
        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                <div className="form-enter p-3 pt-0 mt-4">
                    <div className="row pb-0">
                        <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                        <div className="col-10"><p className="text-green mb-0 fontkanit"><span id="storenametop"></span></p></div>
                    </div>
                    <h2 className="h5 text-center pt-3 mb-0 fontsodo-bold">Select Store</h2>
                    <h3 className="h6 text-center mb-2 fontkanit">เลือกสาขา</h3>
                    <div className="row justify-content-center mb-4">
                        <div className="col-8 text-center"><img src={require("./media/dot-page.png")} alt="Page" /></div>
                    </div>
                    <div className="mb-4">
                        <div className="row">
                            <div className="col-3">Select</div>
                            <div className="col">
                                <label><input onClick={this.switchBlock01} className="form-check-input" type="radio" name="Select" id="Select_0" defaultValue="Find a Store" defaultChecked="checked" />
                                    Find a Store</label>
                            </div>
                            <div className="col">
                                <label><input onClick={this.switchBlock02} className="form-check-input" type="radio" name="Select" id="Select_1" defaultValue="Select Store" />
                                    Select Store</label>
                            </div>
                        </div>
                    </div>
                    <div className={displayB01} >
                        <label className="fontsodo-semibold lh-sm pb-3">Find a Store<br /><span className="fontkanit">ค้นหาสาขา</span><br />
                            <small className="fontkanit" style={{ fontSize: '75%', color: '#555555' }}>*เฉพาะสาขาที่ให้บริการห้องประชุม</small>
                            <Link to={{ pathname: "https://www.starbucks.co.th/th/store-community-room/#Store_Community_Room" }} target="_blank"><img src={require("./media/icon-awesome-info-circle.png")} alt="info" style={{ marginTop: '-3px', width: '17px' }} /></Link>
                        </label>
                        <input type="text" className="form-control fontsodo-semibold" placeholder="Find a Store" id="searchstore" name="searchstore" required />
                    </div>
                    <div className={displayB02} >
                        <label className="fontsodo-semibold lh-sm pb-3">Select Stor<br /><span className="fontkanit">เลือกสาขา</span><br />
                            <small className="fontkanit" style={{ fontSize: '75%', color: '#555555' }}>*เฉพาะสาขาที่ให้บริการห้องประชุม</small>
                            <Link to={{ pathname: "https://www.starbucks.co.th/th/store-community-room/#Store_Community_Room" }} target="_blank"><img src={require("./media/icon-awesome-info-circle.png")} alt="info" style={{ marginTop: '-3px', width: '17px' }} /></Link>
                        </label>
                        <select className="form-select fontsodo-semibold mb-3" required name="province" id="province" value={this.state.province} onChange={this.onInputchange}>
                            {provincelist}
                        </select>
                        <select className="form-select fontsodo-semibold mb-3" required name="amphur" id="amphur" value={this.state.amphur} onChange={this.onInputchange}>
                            {amphurlist}
                        </select>
                        <select className="form-select fontsodo-semibold mb-3" required name="store" id="store" value={this.state.store} onChange={this.onInputchange}>
                            {storelist}
                        </select>
                    </div>
                    <div className="form-group mb-4">
                        <label className="fontsodo-semibold lh-sm pb-2">Date<br /><span className="fontkanit">วันที่ต้องการจอง</span></label>
                        <div className="row gx-0">
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate0}><div className={cssDate0} >{date0showday}<br /><span className="date-text-select-text">{date0showmonth}</span></div></span></div>
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate1}><div className={cssDate1}>{date1showday}<br /><span className="date-text-select-text">{date1showmonth}</span></div></span></div>
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate2}><div className={cssDate2}>{date2showday}<br /><span className="date-text-select-text">{date2showmonth}</span></div></span></div>
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate3}><div className={cssDate3}>{date3showday}<br /><span className="date-text-select-text">{date3showmonth}</span></div></span></div>
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate4}><div className={cssDate4}>{date4showday}<br /><span className="date-text-select-text">{date4showmonth}</span></div></span></div>
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate5}><div className={cssDate5}>{date5showday}<br /><span className="date-text-select-text">{date5showmonth}</span></div></span></div>
                            <div className="col"><span className="spanpointer" onClick={this.onSelectDate6}><div className={cssDate6}>{date6showday}<br /><span className="date-text-select-text">{date6showmonth}</span></div></span></div>
                        </div>
                        <div className="row justify-content-center mt-2 gx-3">
                            <div className="col-5 text-end"><span className="date-text-select" style={{ height: '20px', display: 'inline-block', marginBottom: '-5px' }} /> <small className="fontsodo-semibold">Available</small></div>
                            <div className="col-5 text-start"><span className="date-text-select date-unactive" style={{ height: '20px', display: 'inline-block', marginBottom: '-5px' }} /> <small className="fontsodo-semibold">Unavailable</small></div>
                            <div className="col-12 text-center mt-2"><span className="fontkanit fs-6">*ทำการจองล่วงหน้าได้ไม่เกิน 7 วัน</span></div>
                        </div>
                    </div>
                </div>
                <div className="box-footer-button position-relative">
                    <div className="row text-center mt-3 mb-0 justify-content-center">
                        <div className="col-6">
                            <div className="d-grid">
                                <Link to="/" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-grid">
                                <span className="btn btn-lg mb-0 btn-register-2" onClick={this.onSubmitForm}>Next</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {/* Custom Pop up */}
                    <div id="popup1" className={this.state.ModelCss01}>
                        <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                        <div className="text-center">
                            <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                            <strong>และครบถ้วน</strong>
                        </div>
                        <ul className="text-start">
                            <li className={this.state.ModelCssinner01}>กรุณาเลือกร้าน</li>
                            <li className={this.state.ModelCssinner02}>กรุณาเลือกวันที่จอง</li>
                        </ul>
                        <div className="text-center mt-3 mb-2">
                            <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                        </div>
                    </div>
                </div>

                <div>
                    {/* Custom Pop up */}
                    <div id="popup2" className={this.state.ModelCss02}>
                        <Link to="#" onClick={this.closeModal2} className="close-popup">×</Link>
                        .<br />
                        <p className="text-center fontkanit">
                            <small>The selected date or time is not available.<br />
                                Please try again.<br />
                                วันหรือเวลาที่ท่านเลือกไม่ว่าง<br />
                                กรุณาทำรายการใหม่อีกครั้ง
                            </small>
                        </p>
                        <div className="text-center mt-3 mb-2">
                            <div className="row justify-content-center">
                                <div className="col-6 p-1"><span onClick={this.closeModal2} className="btn btn-sm mb-0 btn-register-2">Close</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
