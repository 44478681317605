import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import { NotInLine } from './components/NotInLine';
import { Mistake } from './components/Mistake';
import { MistakeLine } from './components/MistakeLine';

import { Booking } from './components/Booking';
import { BookingStep2 } from './components/BookingStep2';
import { BookingStep3 } from './components/BookingStep3';
import { BookingStep4 } from './components/BookingStep4';
import { BookingConfirmed } from './components/BookingConfirmed';
import { BookingTerm } from './components/BookingTerm';
import { BookingStep2V2 } from './components/BookingStep2V2';
import { BookingStep4V2 } from './components/BookingStep4V2';
import { BookingStep5 } from './components/BookingStep5';

import { History } from './components/History';
import { HistoryPast } from './components/HistoryPast';
import { HistoryDetail } from './components/HistoryDetail';
import { HistoryCheckIn } from './components/HistoryCheckIn';
import { HistoryCancel } from './components/HistoryCancel';
import { HistorySearch } from './components/HistorySearch';

import { Term } from './components/Term';

import './components/style/custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />

            <Route path='/notinline' component={NotInLine} />
            <Route path='/mistake' component={Mistake} />
            <Route path='/mistakeline' component={MistakeLine} />

            <Route path='/booking' component={Booking} />
            <Route path='/bookingstep2' component={BookingStep2} />
            <Route path='/bookingstep3' component={BookingStep3} />
            <Route path='/bookingstep4' component={BookingStep4} />
            <Route path='/bookingconfirmed' component={BookingConfirmed} />
            <Route path='/bookingterm' component={BookingTerm} />
            <Route path='/bookingstep2v2' component={BookingStep2V2} />
            <Route path='/bookingstep4v2' component={BookingStep4V2} />
            <Route path='/bookingstep5' component={BookingStep5} />

            <Route path='/history' component={History} />
            <Route path='/historypast' component={HistoryPast} />
            <Route path='/historydetail' component={HistoryDetail} />
            <Route path='/historycheckin' component={HistoryCheckIn} />
            <Route path='/historycancel' component={HistoryCancel} />
            <Route path='/historysearch' component={HistorySearch} />

            <Route path='/term' component={Term} />

      </Layout>
    );
  }
}
