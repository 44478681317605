import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'
import svg01 from './media/icon-feather-calendar.svg'
import svg02 from './media/icon-meeting.svg'
import svg03 from './media/icon-feather-clock.svg'
import svg04 from './media/icon-user.svg'
import svg05 from './media/icon-feather-phone.svg'

const liff = window.liff;

export class BookingConfirmed extends Component {
    static displayName = BookingConfirmed.name;

    constructor(props) {
        super(props);

        this.state = {
            jpage: "",
            userLineID: "",
            selectedstore: this.props.location.state.selectedstore,
            selecteddate: this.props.location.state.selecteddate,
            selectroomid: this.props.location.state.selectroomid,
            selectroom: this.props.location.state.selectroom,
            selectroomname: this.props.location.state.selectroomname,
            selectroommaxguest: this.props.location.state.selectroommaxguest,
            thisroomstoreaddr: this.props.location.state.thisroomstoreaddr,
            timestart: this.props.location.state.timestart,
            timeend: this.props.location.state.timeend,
            fullname: this.props.location.state.fullname,
            mobile: this.props.location.state.mobile,
            bookid: this.props.location.state.bookid,
            bookstatus: this.props.location.state.bookstatus,
            thisroomstoretel: this.props.location.state.thisroomstoretel,
            bookingrefno: this.props.location.state.bookingrefno
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            let LineuserId = "x1";
            let LineuserPicture = "";
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end            

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            const dateoptions = {
                weekday: "short", day: "numeric", month: "short", year: "numeric"
            };

            var selecteddate = "";
            switch (this.state.selecteddate) {
                case "D0":
                    selecteddate = date0.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D1":
                    selecteddate = date1.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D2":
                    selecteddate = date2.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D3":
                    selecteddate = date3.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D4":
                    selecteddate = date4.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D5":
                    selecteddate = date5.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D6":
                    selecteddate = date6.toLocaleDateString('en-GB', dateoptions);
                    break;
                default:
                    break;
            }
            //Date list - end

            //
            var selectedstore = this.state.selectedstore;
            var selectroom = this.state.selectroomname;
            var selectroommaxguest = this.state.selectroommaxguest;
            var timestart = this.state.timestart;
            var timeend = this.state.timeend;
            var fullname = this.state.fullname;
            var mobile = this.state.mobile;
            var thisroomstoreaddr = this.state.thisroomstoreaddr;
            var bookid = this.state.bookid;
            var bookstatus = this.state.bookstatus;
            var thisroomstoretel = this.state.thisroomstoretel;
            var thisroomstoremobilelink = "tel:" + this.state.thisroomstoretel;
            var bookingrefno = this.state.bookingrefno;
        }

        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <h2 className="h4 text-center pt-4 mb-0 fontsodo-bold">Booking Status</h2>
                                <h3 className="h5 text-center mb-3 fontkanit fw-normal">สถานะการจอง</h3>
                                <div className="text-center detail-confirmed">
                                    <p className="mb-0"><span className="fs-6 fontsodo-semibold">Your booking is {bookstatus} at</span><br />
                                        <span className="fs-5 fontsodo-bold text-green">{selectedstore}</span><br />
                                        <span className="fs-6 fontsodo-semibold">Tel. <Link to={{ pathname: thisroomstoremobilelink }} target="_blank" className="text-green">{thisroomstoretel}</Link></span></p>
                                    <div className="mb-2">
                                        <Link to={{ pathname: thisroomstoreaddr }} target="_blank" className="btn btn-sm mt-2 mb-0 btn-register-2 fontkanit w-auto"><img src={require("./media/icon-awesome-map-marked-alt.png")} alt="ดูเส้นทาง" className="ms-1 me-2" />ดูเส้นทาง</Link>
                                    </div>
                                    <p className="fs-6 fontsodo-semibold mb-2">Booking No. <span className="fs-5 fontsodo-bold text-green">{bookingrefno}</span></p>
                                    <div className="row justify-content-center">
                                        <div className="col-8">
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold">
                                                <img src={svg01} alt="Date" className="ms-1 me-2" /> {selecteddate} <br />
                                                <img src={svg02} alt="Room" className="ms-1 me-2" /> {selectroom} for {selectroommaxguest} people <br />    
                                                <img src={svg03} alt="Time" className="ms-1 me-2" /> {timestart} to {timeend}</p>
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold">
                                                <img src={svg04} alt="Time" className="ms-1 me-2" />  <span className="text-starbucks-green fontsodo-semibold">{fullname}</span><br />
                                                <img src={svg05} alt="Time" className="ms-1 me-2" /> <span className="fontsodo-semibold">{mobile}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-12 p-0">
                                        <div className="d-grid">
                                            <p className="text-center fontkanit text-green">กรุณาทำการเช็คอินในระบบ 30 นาทีก่อนเวลาจอง</p>
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mt-0 mb-0 btn-register-3">Main Menu</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }                
            </div>

        );
    }
}
