import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'
import svg01 from './media/icon-feather-calendar.svg'
import svg02 from './media/icon-meeting.svg'
import svg03 from './media/icon-feather-clock.svg'
import svg04 from './media/icon-user.svg'
import svg05 from './media/icon-feather-phone.svg'

const liff = window.liff;

export class BookingStep4 extends Component {
    static displayName = BookingStep4.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            jpage: "",
            userLineID: "",
            selectedstore: this.props.location.state.selectedstore,
            selecteddate: this.props.location.state.selecteddate,
            selectroomid: this.props.location.state.selectroomid,
            selectroom: this.props.location.state.selectroom,
            selectroomname: this.props.location.state.selectroomname,
            selectroommaxguest: this.props.location.state.selectroommaxguest,
            thisroomstoreaddr: this.props.location.state.thisroomstoreaddr,
            timestart: this.props.location.state.timestart,
            timeend: this.props.location.state.timeend,
            fullname: this.props.location.state.fullname,
            mobile: this.props.location.state.mobile,
            ans01: "",
            ans02: ""
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onSubmitForm2 = this.onSubmitForm2.bind(this);

        this.closeModal = this.closeModal.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);
        this.closeModal3 = this.closeModal3.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none"
        });
    }

    closeModal2() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide"
        });
    }

    closeModal3() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            ModelCss03: "popuphide"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.ans01) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }        

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Good to go -> show confirm popup
            this.setState({
                ModelCss02: "popup"
            });
        }
    }

    async onSubmitForm2() {
        //Write to db - start
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UserId: this.state.userLineID,
                    selectroomid: this.state.selectroomid,
                    selecteddate: this.state.selecteddate,
                    timestart: this.state.timestart,
                    timeend: this.state.timeend,
                    fullname: this.state.fullname,
                    mobile: this.state.mobile
                }
            )
        };

        await fetch('/api/bookingtransactionadd', requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.responsecode === "OK") {
                    this.props.history.push({
                        pathname: "/BookingConfirmed",
                        state: {
                            selectedstore: this.state.selectedstore,
                            selecteddate: this.state.selecteddate,
                            selectroomid: this.state.selectroomid,
                            selectroom: this.state.selectroom,
                            selectroomname: this.state.selectroomname,
                            selectroommaxguest: this.state.selectroommaxguest,
                            thisroomstoreaddr: this.state.thisroomstoreaddr,
                            timestart: this.state.timestart,
                            timeend: this.state.timeend,
                            fullname: this.state.fullname,
                            mobile: this.state.mobile,
                            bookstatus: "Confirmed",
                            bookid: json.responsemessage,
                            thisroomstoretel: json.thisroomstoretel,
                            bookingrefno: json.bookingrefno
                        }
                    });
                }
                else if (json.responsecode === "E03") {
                    //Show Room have reservation in that time. -> show error popup
                    this.setState({
                        ModelCss03: "popup"
                    });
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: json.responsemessage
                        }
                    });
                }
            });
        //Write to db- end
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "x1";
            //let LineuserPicture = "";                        
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end            

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            const dateoptions = {
                weekday: "short", day: "numeric", month: "short", year: "numeric"
            };

            var selecteddate = "";
            switch (this.state.selecteddate) {
                case "D0":
                    selecteddate = date0.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D1":
                    selecteddate = date1.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D2":
                    selecteddate = date2.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D3":
                    selecteddate = date3.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D4":
                    selecteddate = date4.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D5":
                    selecteddate = date5.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D6":
                    selecteddate = date6.toLocaleDateString('en-GB', dateoptions);
                    break;
                default:
                    break;
            }
            //Date list - end

            //
            var selectedstore = this.state.selectedstore;
            var selectroom = this.state.selectroomname;
            var selectroommaxguest = this.state.selectroommaxguest;
            var timestart = this.state.timestart;
            var timeend = this.state.timeend;
            var fullname = this.state.fullname;
            var mobile = this.state.mobile;
            var thisroomstoreaddr = this.state.thisroomstoreaddr;
        }

        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter p-3 pt-0 mt-0">
                                <h2 className="h5 text-center pt-4 mb-0 fontsodo-bold">Confirm Booking</h2>
                                <h3 className="h6 text-center mb-2 fontkanit fw-normal">ยืนยันการจองห้องประชุม</h3>
                                <div className="detail-confirmed">
                                    <p className="text-center lh-sm mb-1"><span className="fs-6 fontsodo-semibold">Your booking is confirmed at</span><br />
                                        <span className="fs-5 fontsodo-bold text-green">{selectedstore}</span><br />
                                    </p>
                                    <div className="mb-2 text-center">
                                        <Link to={{ pathname: thisroomstoreaddr }} target="_blank" className="btn btn-sm mt-2 mb-0 btn-register-2 fontkanit w-auto"><img src={require("./media/icon-awesome-map-marked-alt.png")} alt="ดูเส้นทาง" className="ms-1 me-2" />ดูเส้นทาง</Link>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-8">
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold mb-0">
                                                <img src={svg01} alt="Date" className="ms-1 me-2" /> <small>{selecteddate}</small> <br />
                                                <img src={svg02} alt="Room" className="ms-1 me-2" /> <small>{selectroom} for {selectroommaxguest} people</small> <br />                                                
                                                <img src={svg03} alt="Time" className="ms-1 me-2" /> <small>{timestart} to {timeend}</small></p>
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold mb-0">
                                                <img src={svg04} alt="Time" className="ms-1 me-2" />  <span className="text-starbucks-green fontsodo-semibold"><small>{fullname}</small></span><br />
                                                <img src={svg05} alt="Time" className="ms-1 me-2" /> <span className="fontsodo-semibold"><small>{mobile}</small></span></p>
                                        </div>
                                        <div className="col-12">
                                            <p className="text-center lh-sm fs-6 fontsodo-semibold mb-1"><small><span className="text-green">We'll alert you 30 mins before your booking.</span><br />
                                                <span className="fontkanit">เราจะแจ้งเตือนล่วงหน้าก่อนถึงเวลาจอง 30 นาที</span></small></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="small text-green pt-2 pb-2 lh-sm">Terms and Conditions <br /><span className="fontkanit">ข้อกำหนดและเงื่อนไขการใช้งานห้องประชุม</span></label>
                                            <div className="box-bg-white-radius-more">
                                                <div style={{ height: '150px', overflow: 'auto' }}>
                                                    <h3 className="fs-6 fontkanit">ข้อกำหนดและเงื่อนไขการใช้งานห้องประชุม </h3>
                                                    <p className="small fontkanit">- เมื่อถึงกำหนดวันและเวลาเข้าใช้งาน โปรดแสดงหลักฐานการจองกับพนักงาน เช่น หมายเลขการจอง ชื่อผู้จองหรือเบอร์โทรศัพท์ พร้อมกดยืนยันการเข้าใช้งานในระบบ<br />
                                                        - หากลูกค้าไม่แสดงตัวเข้าใช้งานภายใน 15 นาที หลังกำหนดเวลาจอง ระบบจะทำการยกเลิกการจองอัตโนมัติ<br />
                                                        - ข้อกำหนดใช้บริการขั้นต่ำสำหรับ 4 ท่าน และไม่เกินจำนวนความจุที่นั่งของห้องประชุม<br />
                                                        - กรณีต้องการเสริมที่นั่งในห้องประชุม ทำได้สูงสุดไม่เกิน 2 ที่นั่ง (กรุณาแจ้งพนักงานสาขา)<br />
                                                        - ลูกค้าต้องสั่งซื้ออาหารและเครื่องดื่ม ยอดเฉลี่ยขั้นต่ำท่านละ 250 บาท (เริ่มต้นที่ 1,000 บาท สำหรับ 4 ท่าน)<br />
                                                        - ลูกค้าสามารถใช้งานได้ตามระยะเวลาจอง และใช้งานหลังครบกำหนดเวลาได้ไม่เกิน 15 นาที (กรณีไม่มีการจองในช่วงเวลาถัดไป)<br />
                                                        - กรณีลูกค้ามีความประสงค์ใช้งานห้องประชุมต่อเนื่องหลังครบกำหนดเวลาจอง และห้องประชุมยังว่างอยู่ ลูกค้าต้องทำการจองใช้งานเพิ่มเติมในระบบ และต้องสั่งซื้ออาหารและเครื่องดื่มเพิ่ม ตามจำนวนผู้เข้าใช้งาน ยอดเฉลี่ยขั้นต่ำท่านละ 250 บาท (เริ่มต้นที่ 1,000 บาทสำหรับ 4 ท่าน) <br />
                                                        - ลูกค้าจะต้องปฏิบัติตามคำแนะนำของร้านค้าเกี่ยวกับการใช้ห้องประชุม<br />
                                                        - ทางร้านมีอุปกรณ์มาตรฐานสำหรับใช้งานห้องประชุม เช่น TV พร้อมสายสัญญาณ HDMI ให้บริการ<br />
                                                        - ไม่อนุญาตให้เคลื่อนย้ายหรือดัดแปลงอุปกรณ์ใช้งานในห้องประชุม<br />
                                                        - ไม่อนุญาตให้นำอาหารและเครื่องดื่มจากภายนอกที่ไม่ใช่ของร้าน Starbucks เข้ามารับประทานในห้องประชุม<br />
                                                        - ไม่อนุญาตให้ใช้งานอุปกรณ์ต่อพ่วง หรือ ส่งเสียงดังในห้องประชุม เช่น ลำโพงหรือไมโครโฟน และไม่ใช้ห้องประชุมในลักษณะที่รบกวนลูกค้าท่านอื่นหรือบุคคลภายนอก <br />
                                                        - ไม่อนุญาตให้ลูกค้าเปิดสื่อลามกอนาจารหรือสิ่งผิดกฏหมายในห้องประชุม และไม่อนุญาตให้กระทำการอื่นใดที่ผิดกฎหมาย หรือทำให้ทรัพย์สินของหรือชื่อเสียงของร้านเสียหาย<br />
                                                        - หลังจากที่การจองได้รับการยืนยันแล้ว หากทราบว่าลูกค้าให้ข้อมูลที่ไม่ถูกต้อง หรือทำให้เข้าใจผิดเกี่ยวกับการจัดประชุม และการจัดประชุมนั้นขัดแย้งกับค่านิยมของเรา ร้านมีสิทธิยกเลิกการจองนั้นได้<br />
                                                        - จำกัดการจอง Community Room 2 ครั้ง / วัน / 1 บัญชีผู้ใช้งาน (กรณียกเลิกการจอง ขอสงวนสิทธิ์ไม่คืนสิทธิ์การจอง)</p>
                                                    <h3 className="fs-6 fontkanit">Terms and Conditions for Using Meeting Room </h3>
                                                    <p className="small fontkanit">- In order to check-in, please show your booking details to the staff, such as the booking number, customer's name or phone number, and indicate your confirmation to check-in in the system.<br />
                                                        - If customer does not show up within 15 minutes after the booking time.  The system will automatically cancel the booking.<br />
                                                        - Minimum number of users is 4 persons and not exceed a meeting room seating capacity.<br />
                                                        - The maximum of extra seats that the customer may request in the meeting room is not exceeding 2 seats (please inform store’s staff).<br />
                                                        - Customers must order food and beverage with a minimum average purchase amount of 250 baht per person (starting at 1,000 baht for 4 persons).<br />
                                                        - Customers can use the room for the duration of the booking period and up to 15 minutes after the end of the time (if there is no booking in the next period).<br />
                                                        - In case the customer wishes to continue using the meeting room after the booking time end and the meeting room is still available. Customer must make additional booking in the system. Additional food and beverages must be ordered according to the number of visitors. Minimum average purchase amount is 250 baht per person (starting from 1,000 baht for 4 persons).<br />
                                                        - The customer is obligated to follow the store's instructions with respect to the use of the meeting room. <br />
                                                        - The shop has standard equipment for meeting rooms such as TV with HDMI cable.<br />
                                                        - Moving or modifying equipment is not permitted in meeting rooms.<br />
                                                        - Food and drinks from outside are not allowed.<br />
                                                        - Peripherals or loud noises are not allowed in meeting rooms such as speakers or microphones.  The customers are obligated to use the meeting room in a manner that does not disturb other guests or third parties.<br />
                                                        - Customer is not allowed to open pornographic or illegal materials in the meeting room.  The customer shall not conduct any illegal actions or any acts that damages our property or reputation.<br />
                                                        - Should it, after the booking has been confirmed, become known that the customer has provided incorrect or misleading information regarding the meeting and such meeting conflicts with our values, we are entitled to cancel or abort the booking.<br />
                                                        - Limit to 2 Community Room Bookings per day per 1 LINE user account. (In case of cancellation,  such booking right shall be forfeited.)<br />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" name="ans01" defaultValue={this.state.ans01} onChange={this.onInputchange} />
                                    <label className="form-check-label text-green fontsodo-semibold"><small>I agree to the terms and conditions <span className="fontkanit">รับทราบ ข้อกำหนดและเงื่อนไขของการใช้ห้องประชุม</span></small></label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="ans02" defaultValue={this.state.ans02} onChange={this.onInputchange} />
                                    <label className="form-check-label text-green"><small className="fontkanit">รับทราบและตกลงตามเอกสารการแจ้งข้อมูลการประมวลผลข้อมูลส่วนบุคคล <Link to={{ pathname: "https://www.starbucks.co.th/th/online-policies/privacy-notice/" }} className="text-green fontsodo-semibold" style={{ textDecoration: 'underline' }} target="_blank" >(PRIVACY NOTICE)</Link></small></label>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-0 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mb-0 btn-register-cancelled">Cancel</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <span className="btn btn-lg mb-0 btn-register-2" onClick={this.onSubmitForm}>Confirm</span>
                                        </div>
                                    </div>

                                    <div>
                                        {/* Custom Pop up1 */}
                                        <div id="popup1" className={this.state.ModelCss01}>
                                            <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                            <div className="text-center">
                                                <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                                <strong>และครบถ้วน</strong>
                                            </div>
                                            <ul className="text-start">
                                                <li className={this.state.ModelCssinner01}>กรุณายอมรับข้อกำหนดและเงื่อนไขของกิจกรรม</li>
                                            </ul>
                                            <div className="text-center mt-3 mb-2">
                                                <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {/* Custom Pop up2 */}
                                        <div id="popup2" className={this.state.ModelCss02}>
                                            <Link to="#" onClick={this.closeModal2} className="close-popup">×</Link>
                                            <p className="text-center fontkanit text-black mt-4 mb-0"><small>Click to confirm or edit the booking.</small></p>
                                            <p className="text-center fontkanit text-black"><small>กด Confirm เพื่อยืนยันการจอง หรือกด Edit เพื่อกลับไปแก้ไขรายละเอียดการจอง</small></p>
                                            <div className="text-center mt-3 mb-2">
                                                <div className="row">
                                                    <div className="col-6 p-1"><Link to="/Booking" className="btn btn-sm mb-0 btn-outline-white-2">Edit</Link></div>
                                                    <div className="col-6 p-1">
                                                        <input type="button" className="btn btn-sm mb-0 btn-register-2" onClick={this.onSubmitForm2} defaultValue="Confirm" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {/* Custom Pop up3 */}
                                        <div id="popup3" className={this.state.ModelCss03}>
                                            <Link to="/Booking" className="close-popup">×</Link>
                                            <p className="text-center fontkanit text-black mt-4 mb-0"><small>The selected date or time is not available. Please try again</small></p>
                                                <p className="text-center fontkanit text-black"><small>ขณะนี้วัน หรือเวลาของห้องประชุมที่เลือกไม่ว่าง กรุณาทำรายการใหม่อีกครั้ง</small></p>
                                            <div className="text-center mt-3 mb-2">
                                                <div className="row justify-content-center">
                                                    <div className="col-6 p-1"><Link to="/Booking" className="btn btn-sm mb-0 btn-register-2">Close</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }                               
            </div>
        );
    }
}
