import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'
import svg01 from './media/icon-feather-calendar.svg'
import svg02 from './media/icon-meeting.svg'
import svg03 from './media/icon-feather-clock.svg'

const liff = window.liff;

export class HistoryPast extends Component {
    static displayName = HistoryPast.name;

    constructor(props) {
        super(props);

        this.state = {
            userLineID: "",
            bookinglist: []
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "x1";
            //let LineuserPicture = "";                        
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end

                    //retrive upcoming booking list - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/bookinglistpast', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                bookinglist: json
                            });
                        });
                    //retrive upcoming booking list  - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //upcoming booking list - start
            var bookinglist = [];
            var cssbutton01 = "";
            var bookstatus = "";
            for (let i = 0; i < this.state.bookinglist.length; i++) {
                switch (this.state.bookinglist[i].status) {
                    case "Confirmed": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-2"; bookstatus = "Confirmed"; break;
                    case "Check-in": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-4"; bookstatus = "Checked-in"; break;
                    case "Cancel": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-cancelled"; bookstatus = "Cancelled"; break;
                    case "Completed": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-completed"; bookstatus = "Completed"; break;
                    default: cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-completed"; bookstatus = this.state.bookinglist[i].status; break;
                }

                bookinglist.push(
                    <div className="card card-room-list mb-1" key={i}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-8"><div className="head-room-name">{this.state.bookinglist[i].storeName}</div></div>
                                <div className="col-4 text-end lh-1"><span className="booking-no">Booking No.</span><br /><span className="booking-number">{this.state.bookinglist[i].bookingRefNo}</span></div>
                            </div>
                            <div className="row row-room-detail">
                                <div className="col-8 p-0 ps-2">
                                    <div><span className="room-text-detail"><img src={svg01} alt="Date" className="ms-1" /> {this.state.bookinglist[i].dateStartTxt}</span></div>
                                    <div><span className="room-detail"><img src={svg02} alt="Room" className="ms-1" /> {this.state.bookinglist[i].roomName} for {this.state.bookinglist[i].roomMaxGuest} people</span></div>                                    
                                    <div><img src={svg03} alt="Time" className="ms-2" /> <span className="room-text-detail">{this.state.bookinglist[i].timeStart} - {this.state.bookinglist[i].timeEnd}</span></div>
                                </div>
                                <div className="col-4 text-end align-self-end ps-1 pe-1">
                                    <span className={cssbutton01} >{bookstatus}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            //upcoming booking list - end
        }
        return (
            <div className="container">
                <div className="form-signin shadow bg-home position-relative">
                    <div className="head-nav p-0">
                        <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                    </div>
                    {
                        (this.state.userLineID && this.state.userLineID !== '')
                            ?
                            <>
                                <div className="form-enter pt-0 mt-4">
                                    <h2 className="h4 text-center pt-0 mb-0 fontsodo-bold">My Booking</h2>
                                    <div className="row p-3 pb-2">
                                        <div className="col-6 p-1 text-center">
                                            <Link to="/history" className="btn btn-outline-white"> <span>Upcoming</span></Link>
                                        </div>
                                        <div className="col-6 p-1 text-center">
                                            <Link to="/historypast" className="btn  btn-outline-darkgreen"> <span>History</span></Link>
                                        </div>
                                    </div>
                                    <div className="row p-2 pt-0 pe-0 row-card-room-list-2">
                                        <div className="col-12 mb-2">
                                            {bookinglist}
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer-button position-relative">
                                    <div className="row text-center mt-3 mb-0 justify-content-center">
                                        <div className="col-6 p-0">
                                            <div className="d-grid">
                                                <Link to="/" className="btn btn-lg mb-0 btn-register-3">Main Menu</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="form-enter mt-1 pb-0">
                                    <form>
                                        <div className="text-center" style={{ height: '160px' }} />
                                        <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                            <h3 className="text-white">LOADING ...</h3>
                                            <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                        </div>
                                        <div style={{ height: '100px' }} />
                                    </form>
                                </div>
                            </>
                    }                    
                </div>
            </div>

        );
    }
}
