import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import myHeader from './media/stb_booking.png'

const liff = window.liff;

export class BookingStep2V2 extends Component {
    static displayName = BookingStep2V2.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner01: "",
            ModelCssinner02: "",
            ModelCssinner03: "d-none",
            jpage: "",
            userLineID: "",
            selectedstore: this.props.location.state.selectedstore,
            selecteddate: this.props.location.state.selecteddate,
            roomlist: [],
            totalroom: 0,
            selectroom: "",
            cssroom0: "btn btn-normal btn-outline-white d-none",
            cssroom1: "btn btn-normal btn-outline-white d-none",
            cssroom2: "btn btn-normal btn-outline-white d-none",
            cssroom3: "btn btn-normal btn-outline-white d-none",
            cssroom4: "btn btn-normal btn-outline-white d-none",
            timestart: "",
            timeend: "",
            maxhour: 0,
            timeOpen: "",
            timeClose: "",
            timelist: []
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSelectRoom0 = this.onSelectRoom0.bind(this);
        this.onSelectRoom1 = this.onSelectRoom1.bind(this);
        this.onSelectRoom2 = this.onSelectRoom2.bind(this);
        this.onSelectRoom3 = this.onSelectRoom3.bind(this);
        this.onSelectRoom4 = this.onSelectRoom4.bind(this);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            ModelCssinner03: "d-none"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.selectroom) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.timestart) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.timeend) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Good to go!!

            //Check total hour
            const maxmin = this.state.maxhour * 60;
            const date0 = new Date().setHours(this.state.timestart.slice(0, 2), this.state.timestart.slice(-2));
            const date1 = new Date().setHours(this.state.timeend.slice(0, 2), this.state.timeend.slice(-2));

            var diffMins = Math.round((date1 - date0) / 60000);
            if (diffMins > maxmin || diffMins <= 0) {
                //More than max min or time end > time start
                this.setState({
                    ModelCssinner03: "",
                    ModelCss01: "popup"
                });
            }
            else {
                //-> push to step3
                var thisroomid = this.state.roomlist[this.state.selectroom].roomId;
                var thisroomname = this.state.roomlist[this.state.selectroom].roomName;
                var thisroommaxguest = this.state.roomlist[this.state.selectroom].maxGuestTxt;
                var thisroomstoreaddr = this.state.roomlist[this.state.selectroom].storeAddr;

                this.props.history.push({
                    pathname: "/BookingStep3",
                    state: {
                        selectedstore: this.state.selectedstore,
                        selecteddate: this.state.selecteddate,
                        selectroom: this.state.selectroom,
                        selectroomid: thisroomid,
                        selectroomname: thisroomname,
                        selectroommaxguest: thisroommaxguest,
                        thisroomstoreaddr: thisroomstoreaddr,
                        timestart: this.state.timestart,
                        timeend: this.state.timeend
                    }
                });
            }
        }
    }

    async onSelectRoom0() {
        this.setState({
            selectroom: "0",
            maxhour: this.state.roomlist[0].maxHour,
            jpage: ''
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white room-active",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end

        //check fully book on that room - start
        const requestOptions4 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    selectroomid: this.state.roomlist[0].roomId,
                    selecteddate: this.state.selecteddate
                }
            )
        };
        await fetch('/api/BookingCheckFullyOnRoom', requestOptions4)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    timelist: json
                });
            });
        //check fully book on that room - end

        this.setState({
            jpage: 'done'
        });
    }

    async onSelectRoom1() {
        this.setState({
            selectroom: "1",
            maxhour: this.state.roomlist[1].maxHour,
            jpage: ''
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white room-active",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end

        //check fully book on that room - start
        const requestOptions4 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    selectroomid: this.state.roomlist[1].roomId,
                    selecteddate: this.state.selecteddate
                }
            )
        };
        await fetch('/api/BookingCheckFullyOnRoom', requestOptions4)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    timelist: json
                });
            });
        //check fully book on that room - end

        this.setState({
            jpage: 'done'
        });
    }

    async onSelectRoom2() {
        this.setState({
            selectroom: "2",
            maxhour: this.state.roomlist[2].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white room-active",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white room-active",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white room-active",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    async onSelectRoom3() {
        this.setState({
            selectroom: "3",
            maxhour: this.state.roomlist[3].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white room-active",
                    cssroom4: "btn btn-normal btn-outline-white d-none"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white room-active",
                    cssroom4: "btn btn-normal btn-outline-white"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    async onSelectRoom4() {
        this.setState({
            selectroom: "4",
            maxhour: this.state.roomlist[4].maxHour
        });

        //Show room for select
        var roomtotal = this.state.roomlist.length - 1;
        switch (roomtotal) {
            case 0:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white d-none",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 1:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white d-none",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 2:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white d-none",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 3:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white d-none room-active"
                });
                break;
            case 4:
                this.setState({
                    cssroom0: "btn btn-normal btn-outline-white",
                    cssroom1: "btn btn-normal btn-outline-white",
                    cssroom2: "btn btn-normal btn-outline-white",
                    cssroom3: "btn btn-normal btn-outline-white",
                    cssroom4: "btn btn-normal btn-outline-white room-active"
                });
                break;
            default:
                break;
        }
        //retrive room list - end
    }

    componentDidMount() {
        this.populateProfile();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "U0b81f50b7ae5779640000176e283010a";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1653885335-wWBy9Lq0'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Member - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberchecke', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //Check Member -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Member not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Member - end

                    //retrive room list - start
                    const requestOptions3 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                Storename: this.state.selectedstore,
                                selecteddate: this.state.selecteddate
                            }
                        )
                    };
                    await fetch('/api/roomlist', requestOptions3)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                roomlist: json,
                                totalroom: json.length
                            });
                        });

                    //Show room for select
                    var roomtotal = this.state.roomlist.length - 1;
                    switch (roomtotal) {
                        case 0:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white d-none",
                                cssroom2: "btn btn-normal btn-outline-white d-none",
                                cssroom3: "btn btn-normal btn-outline-white d-none",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 1:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white d-none",
                                cssroom3: "btn btn-normal btn-outline-white d-none",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 2:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white",
                                cssroom3: "btn btn-normal btn-outline-white d-none",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 3:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white",
                                cssroom3: "btn btn-normal btn-outline-white",
                                cssroom4: "btn btn-normal btn-outline-white d-none"
                            });
                            break;
                        case 4:
                            this.setState({
                                cssroom0: "btn btn-normal btn-outline-white",
                                cssroom1: "btn btn-normal btn-outline-white",
                                cssroom2: "btn btn-normal btn-outline-white",
                                cssroom3: "btn btn-normal btn-outline-white",
                                cssroom4: "btn btn-normal btn-outline-white"
                            });
                            break;
                        default:
                            break;
                    }
                    //retrive room list - end


                    //OverRide select room 0 on load - start
                    this.setState({
                        selectroom: "0",
                        maxhour: this.state.roomlist[0].maxHour,
                        timeOpen: this.state.roomlist[0].timeOpen,
                        timeClose: this.state.roomlist[0].timeClose,
                        cssroom0: "btn btn-normal btn-outline-white room-active"
                    });
                    //OverRide select room 0 on load - end


                    //check fully book on that room - start
                    const requestOptions4 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                selectroomid: this.state.roomlist[0].roomId,
                                selecteddate: this.state.selecteddate
                            }
                        )
                    };
                    await fetch('/api/BookingCheckFullyOnRoom', requestOptions4)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                timelist: json
                            });
                        });
                    //check fully book on that room - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            var selectedstore = this.state.selectedstore;

            //Date list - start
            const date0 = new Date();
            const date1 = new Date(new Date().setDate(new Date().getDate() + 1));
            const date2 = new Date(new Date().setDate(new Date().getDate() + 2));
            const date3 = new Date(new Date().setDate(new Date().getDate() + 3));
            const date4 = new Date(new Date().setDate(new Date().getDate() + 4));
            const date5 = new Date(new Date().setDate(new Date().getDate() + 5));
            const date6 = new Date(new Date().setDate(new Date().getDate() + 6));

            const dateoptions = {
                weekday: "short", day: "numeric", month: "short", year: "numeric"
            };

            var selecteddate = "";
            switch (this.state.selecteddate) {
                case "D0":
                    selecteddate = date0.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D1":
                    selecteddate = date1.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D2":
                    selecteddate = date2.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D3":
                    selecteddate = date3.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D4":
                    selecteddate = date4.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D5":
                    selecteddate = date5.toLocaleDateString('en-GB', dateoptions);
                    break;
                case "D6":
                    selecteddate = date6.toLocaleDateString('en-GB', dateoptions);
                    break;
                default:
                    break;
            }
            //Date list - end                       

            //Room list - start                        
            var roomname0 = "";
            var roomname1 = "";
            var roomname2 = "";
            var roomname3 = "";
            var roomname4 = "";
            var roommaxguest0 = "";
            var roommaxguest1 = "";
            var roommaxguest2 = "";
            var roommaxguest3 = "";
            var roommaxguest4 = "";
            for (let i = 0; i < this.state.roomlist.length; i++) {
                switch (i) {
                    case 0:
                        roommaxguest0 = this.state.roomlist[i].maxGuestTxt;
                        roomname0 = this.state.roomlist[i].roomName; break;
                    case 1:
                        roommaxguest1 = this.state.roomlist[i].maxGuestTxt;
                        roomname1 = this.state.roomlist[i].roomName; break;
                    case 2:
                        roommaxguest2 = this.state.roomlist[i].maxGuestTxt;
                        roomname2 = this.state.roomlist[i].roomName; break;
                    case 3:
                        roommaxguest3 = this.state.roomlist[i].maxGuestTxt;
                        roomname3 = this.state.roomlist[i].roomName; break;
                    case 4:
                        roommaxguest4 = this.state.roomlist[i].maxGuestTxt;
                        roomname4 = this.state.roomlist[i].roomName; break;
                    default: break;
                }
            }
            //Room list - end

            //Time list - start
            var openslot = this.state.timelist.filter(function (item) { return (item.timeType === "open"); });
            var closeslot = this.state.timelist.filter(function (item) { return (item.timeType === "close"); });

            var timeStartlist = [];
            timeStartlist.push(<option key="-1" value="" disabled></option>)
            for (let i = 0; i < openslot.length; i++) {
                timeStartlist.push(<option key={i} value={openslot[i].txtValue} disabled={openslot[i].css01}>{openslot[i].txtShow}</option>)
            }

            var timeEndlist = [];
            timeEndlist.push(<option key="-1" value="" disabled></option>)
            for (let i = 0; i < closeslot.length; i++) {
                timeEndlist.push(<option key={i} value={closeslot[i].txtValue} disabled={closeslot[i].css01}>{closeslot[i].txtShow}</option>)
            }
            //Time list - end


            var maxHour = this.state.maxhour;
        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={myHeader} alt="Booking Community Room" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.jpage && this.state.jpage !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <div className="form-enter p-3 pt-0 mt-4">
                                    <div className="row pb-0">
                                        <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                                        <div className="col-10"><p className="text-green mb-0 fontkanit">{selectedstore}</p></div>
                                    </div>
                                    <div className="row pb-0">
                                        <div className="col-2"><p className="fontsodo-semibold mb-0">Date</p></div>
                                        <div className="col-8"><p className="text-green mb-0 fontkanit">{selecteddate}</p></div>
                                    </div>
                                    <h2 className="h5 text-center pt-3 mb-0 fontsodo-bold">Select Room</h2>
                                    <h3 className="h6 text-center mb-2 fontkanit">เลือกห้องประชุม</h3>
                                    <div className="row justify-content-center mb-4">
                                        <div className="col-8 text-center"><img src={require("./media/dot-page2.png")} alt="Page2" /></div>
                                    </div>
                                    <div className="d-grid gap-2 mb-5">
                                        <span className={this.state.cssroom0} onClick={this.onSelectRoom0}>{roomname0} <br /><small>For {roommaxguest0} people</small></span>
                                        <span className={this.state.cssroom1} onClick={this.onSelectRoom1}>{roomname1} <br /><small>For {roommaxguest1} people</small></span>
                                        <span className={this.state.cssroom2} onClick={this.onSelectRoom2}>{roomname2} <br /><small>For {roommaxguest2} people</small></span>
                                        <span className={this.state.cssroom3} onClick={this.onSelectRoom3}>{roomname3} <br /><small>For {roommaxguest3} people</small></span>
                                        <span className={this.state.cssroom4} onClick={this.onSelectRoom4}>{roomname4} <br /><small>For {roommaxguest4} people</small></span>
                                    </div>
                                    <h2 className="h5 text-center pt-3 mb-0 fontsodo-bold">Select Time</h2>
                                    <h3 className="h6 text-center mb-2 fontkanit">เวลาในการเข้าใช้ห้องประชุม</h3>
                                    <div className="form-group mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="fontsodo-semibold lh-sm pb-2"><span className="text-green">Start</span></label>
                                                <select className="form-control" required name="timestart" id="timestart" value={this.state.timestart} onChange={this.onInputchange}>
                                                    {timeStartlist}
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <label className="fontsodo-semibold lh-sm pb-2"><span className="text-housegreen">End</span></label>
                                                <select className="form-control" required name="timeend" id="timeend" value={this.state.timeend} onChange={this.onInputchange}>
                                                    {timeEndlist}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col"><p className="fontsodo-semibold">Maximum {maxHour} hours per booking</p></div>
                                        </div>
                                        <div className="row justify-content-center mt-0 gx-3">
                                            <div className="col-6 text-end"><span className="date-text-select" style={{ height: '20px', display: 'inline-block', marginBottom: '-5px' }} /> <small className="fontsodo-semibold">Available</small></div>
                                            <div className="col-6 text-start"><span className="date-text-select date-unactive" style={{ height: '20px', display: 'inline-block', marginBottom: '-5px' }} /> <small className="fontsodo-semibold">Unavailable</small></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="box-footer-button position-relative">
                                    <div className="row text-center mt-3 mb-0 justify-content-center">
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <Link to="/Booking" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-grid">
                                                <span className="btn btn-lg mb-0 btn-register-2" onClick={this.onSubmitForm}>Next</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {/* Custom Pop up */}
                                    <div id="popup1" className={this.state.ModelCss01}>
                                        <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                        <div className="text-center">
                                            <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                            <strong>และครบถ้วน</strong>
                                        </div>
                                        <ul className="text-start">
                                            <li className={this.state.ModelCssinner01}>กรุณาเลือกห้องประชุม</li>
                                            <li className={this.state.ModelCssinner02}>กรุณาเลือกเวลาที่ต้องการจอง</li>
                                            <li className={this.state.ModelCssinner03}>กรุณาตรวจสอบเวลาที่จอง</li>
                                        </ul>
                                        <div className="text-center mt-3 mb-2">
                                            <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}
